/**=====================
    3.18 Dashboard_10 Start
==========================**/

.visits-wrapper {
    .card-body {
        .common-flex {
            background-color: var(--course-light-btn);
            padding: 10px 12px;
            border-radius: 8px;
            align-items: center;
            width: fit-content;
        }
    }
}

.treading-product {
    .recent-table {
        table {
            tbody {
                tr {
                    td {
                        .referral-wrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 12px;

                            svg {
                                width: 16px;
                                height: 16px;
                            }
                        }

                        &:nth-child(2) {
                            @media (max-width:812px) {
                                min-width: 135px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.referral-visit {
    .table {

        thead,
        tbody {
            tr {

                th,
                td {
                    min-width: auto;

                    .btn {
                        @media (max-width:1731px) {
                            padding: 6px 16px;
                        }
                    }

                    span {
                        @media (max-width:1695px) and (min-width:1456px) {
                            display: none;
                        }
                    }

                    @media (max-width:1455px) and (min-width:1200px) {
                        &:nth-child(2) {
                            display: none;
                        }
                    }

                    .referral-wrapper {
                        .bg-dark-primary {
                            border: 1px solid $fb-color;

                            .social-wrapper {
                                background-color: rgba(47, 132, 244, 0.1);
                            }
                        }

                        >div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;

                            >div {
                                width: 34px;
                                height: 34px;
                                border: 1px solid $fb-color;
                                border-radius: 50%;
                                padding: 3px;

                                .social-wrapper {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    min-width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                }

                            }
                        }
                    }
                }

                &:last-child {
                    td {
                        border: unset;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}

.dashboard-10 {
    .card {
        &.heading-space {
            .header-top {
                @media (max-width:420px) {
                    margin-left: 0;
                }
            }
        }
    }
}

.page-analytics-table {
    .recent-table {
        div.dt-container {
            .dt-layout-row {
                @media (max-width:420px) {
                    position: relative;
                }

                .dt-layout-cell {
                    .dt-search {
                        right: 60px;
                        top: 24px;

                        @media (max-width:1500px) {
                            position: relative;
                            top: 4px;
                            left: 20px;
                            width: 90%;
                            margin-bottom: 20px;
                        }

                        @media (max-width:420px) {
                            left: unset;
                            right: -130px;
                        }
                    }
                }

                &:first-child {
                    @media (max-width:420px) {
                        .dt-length {
                            top: 0px;
                            left: 20px;
                        }
                    }
                }

                &:last-child {
                    flex-direction: column;
                }
            }
        }

        .table {

            thead,
            tbody {
                tr {

                    th,
                    td {
                        color: var(--body-font-color);
                        min-width: auto;

                        &:first-child {
                            padding-left: 20px;
                        }

                        &:last-child {
                            padding-right: 20px;
                        }

                        @media (max-width:1588px) and (min-width:1200px) {
                            &:nth-child(5) {
                                display: none;
                            }
                        }

                        @media (max-width:1368px) and (min-width:1200px) {
                            &:nth-child(4) {
                                display: none;
                            }
                        }

                        @media (max-width:684px) {
                            &:nth-child(2) {
                                min-width: 104px;
                            }

                            &:nth-child(3),
                            &:nth-child(4),
                            &:nth-child(5) {
                                min-width: 118px;
                            }
                        }

                        svg {
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }
        }
    }
}

.live-user-wrapper {
    .user-data {
        margin-bottom: 8px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        align-items: center;

        h5 {
            margin-bottom: 4px;
        }

        span {
            svg {
                width: 14px;
                height: 14px;
                vertical-align: middle;
            }
        }
    }
}

.analytics-widget {
    background-image: url(/assets/images/dashboard-10/light-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;

    @media (max-width:575px) {
        text-align: center;
        background-position: top;
    }

    img {
        height: 138px;

        @media (max-width:450px) {
            height: 115px;
        }
    }

    .btn {
        min-width: auto;

        @media (max-width:1485px) {
            padding: 8px 14px;
            font-size: 14px;
        }
    }

    h5 {
        @media (max-width:1468px) {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .card-text {
        @media (max-width:1789px) {
            display: -webkit-box;
            max-width: 100%;
            height: 46px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: unset;
        }
    }
}

.analytics-tread-card {
    &:hover {
        transform: translateY(-5px);
        transition: 0.5s;
    }

    .header-top {
        svg {
            width: 14px;
            height: 14px;
        }
    }

    .progress {
        height: 12px;
        background-color: rgba($info-color, 10%);
        margin: 58px 0 20px;

        @media (max-width:1700px) {
            height: 8px;
        }

        @media (max-width:1500px) and (min-width:1200px) {
            margin: 90px 0 20px;
        }

        @media (max-width:1199px) {
            margin: 40px 0 20px;

        }

        .progress-bar-striped {
            background-image: linear-gradient(45deg, rgba(255, 255, 255, .80) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .80) 50%, rgba(255, 255, 255, .80) 75%, transparent 75%, transparent);
        }
    }

    .analytics-tread {
        width: calc(48px + (58 - 48) * ((100vw - 320px) / (1920 - 320)));
        height: calc(48px + (58 - 48) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
            height: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .session-progress {
        .progress {
            background-color: rgba($success-color, 10%);

            .small-progressbar {
                .animate-circle {
                    border: 5px solid rgba($success-color, 0.62);
                    background-color: (90deg, rgba(101, 193, 92, 0.2) 0%, #65C15C 76.58%);
                    top: -10px;
                    right: -16px;
                    padding: 10px;
                    animation: unset;

                    @media (max-width:1700px) {
                        top: -11px;
                    }
                }

                &::after {
                    background-color: $success-color;
                }
            }

            .small-progressbar {
                &::after {
                    top: -3px;
                    right: -9px;
                    width: 16px;
                    height: 16px;

                    @media (max-width:1700px) {
                        top: -3px;
                        width: 14px;
                        height: 14px;
                    }
                }
            }

            .progress-label {
                top: -55px;
                right: -20px;
                background-color: $success-color;
                border-radius: 6px;
                padding: 2px 8px;
                font-size: 14px;
                color: $white;
                font-weight: 600;
                font-family: $font-roboto, $font-serif;

                @media (max-width:1399px) {
                    top: -50px;
                    right: -16px;
                    font-size: 12px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 50%;
                    bottom: 0;
                    border: 8px solid $transparent-color;
                    border-bottom: 0;
                    border-top: 8px solid $success-color;
                    transform: translate(-50%, calc(100% - 0px));
                }
            }
        }
    }

    &.retention-progress {
        .card-body {
            .progress {
                margin-top: 38px;

                @media (max-width:1500px) and (min-width:1200px) {
                    margin-top: 70px;
                }

                @media (max-width:1199px) {
                    margin-top: 12px;
                }
            }
        }
    }

    .header-top {
        .common-align {
            align-items: baseline;
            gap: 8px;

            >span {
                @media (max-width:1345px) and (min-width:1200px) {
                    display: none;
                }
            }
        }

        >div {
            span {
                @media (max-width:1426px) and (min-width:1200px) {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 130px;
                }

                @media (max-width:1320px) and (min-width:1231px) {
                    max-width: 104px;
                }

                @media (max-width:1230px) and (min-width:1200px) {
                    max-width: 80px;
                }

                @media (max-width:592px) and (min-width:576px) {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 106px;
                }

            }
        }
    }
}

.user-analytics-wrapper {
    margin: -40px -10px -20px -30px;

    @media (max-width:575px) {
        margin: -40px 0px -20px -20px;
    }
}

.analytics-view-wrapper {
    margin: -34px -10px -30px 0px;
    overflow: hidden;
}

.visit-duration-wrapper {
    margin: -34px 35px -30px 0px;
}

.bounce-rate-wrapper {
    margin: -34px -20px -30px 0px;
    overflow: hidden;
}

.main-session-devices {
    .common-align {
        flex-direction: column;
        gap: 32px;
        align-items: unset;

        @media (max-width:575px) {
            gap: 8px;
        }

        p {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
        }

        .common-space {
            span {
                @media (max-width:1290px) and (min-width:1200px) {
                    display: none;
                }

                @media (max-width:850px) and (min-width:768px) {
                    display: none;
                }
            }
        }
    }

    .progress {
        height: 10px;
        background: linear-gradient(180.36deg, #CFDCE8 -32.82%, #FFFFFF 132.82%);
        border-radius: 100px;
    }
}

.session-browser-table {
    .table-responsive {
        .table {

            thead,
            tbody {
                tr {

                    th,
                    td {
                        border: unset;
                        color: var(--body-font-color);
                        min-width: auto;

                        &:first-child {
                            padding-left: 20px;
                        }

                        &:last-child {
                            padding-right: 20px;
                        }

                        .session-name {
                            color: var(--body-font-color);
                            font-weight: 500;

                            @media (max-width:1255px) and (min-width:1200px) {
                                display: none;
                            }
                        }

                        .common-flex {
                            @media (max-width:1272px) and (min-width:1200px) {
                                gap: 8px;
                            }

                            img {
                                @media (max-width:1255px) and (min-width:1200px) {
                                    height: 20px;
                                }
                            }
                        }

                        @media (max-width:1752px) and (min-width:1440px) {
                            padding-block: 9px;
                        }

                        @media (max-width:1439px) and (min-width:1200px) {
                            padding-block: 7px;
                        }
                    }

                    &:last-child {
                        td {
                            padding-bottom: 0;
                        }
                    }
                }
            }

            thead {
                tr {
                    th {
                        color: $badge-light-color;
                    }
                }
            }

            tbody {
                tr {
                    @media (max-width:1439px) and (min-width:1200px) {
                        &:nth-child(4) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.jvm-zoom-btn {
    display: none;
}

.live-user-map {
    #map {
        height: 245px;

        @media (max-width:1752px) and (min-width:1571px) {
            height: 210px;
        }

        @media (max-width:1570px) and (min-width:1440px) {
            height: 180px;
        }

        @media (max-width:1439px) and (min-width:768px) {
            height: 150px;
        }

        @media (max-width:767px) {
            height: 150px;
        }
    }
}

.common-m-chart {
    margin-bottom: -20px;

    @media (max-width:458px) {
        margin-bottom: -40px;
    }
}

@media (max-width:1199px) {
    .ord-md-i {
        order: 1;
    }

    .ord-md-ii {
        order: 2;
    }

    .ord-md-iii {
        order: 3;
    }

    .ord-md-iv {
        order: 4;
    }

    .ord-md-v {
        order: 5;
    }

    .ord-md-vi {
        order: 6;
    }

    .ord-md-vii {
        order: 7;
    }

    .ord-md-viii {
        order: 8;
    }

    .ord-md-ix {
        order: 9;
    }

    .ord-md-x {
        order: 10;
    }
}

/**=====================
    3.18 Dashboard_10 Start
==========================**/