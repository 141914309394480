/**=====================
  3.37 Kanban CSS start
==========================**/
.jkanban-container {

  .note {
    a {
      display: contents;
    }
  }

  .common-f-start {
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;

    .btn {
      text-align: left;
    }
  }

  .kanban-container {
    .kanban-title-board {
      font-weight: 500;
      font-size: 17px;
    }

    footer {
      padding: 0;

      .btn {
        width: 100%;
        padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 1.2px;
        border-radius: 0;

        &::before {
          content: "\2b";
          font-family: $font-awesome;
          padding-right: 8px;

          [dir="rtl"] & {
            padding-right: unset;
            padding-left: 8px;
          }
        }

        &:hover {
          background-color: var(--light-background);
        }

        &:active {
          border-color: $transparent-color;
        }
      }
    }
  }
}

.kanban-board-header {
  height: auto;
  width: auto;
  line-height: unset;
  background: rgba($main-body-color, 0.9);
  border-bottom: 1px solid #ddd;

  &.bg-primary {
    &~.kanban-drag {
      background-color: rgba($primary-color, 0.3) !important;
    }
  }

  &.bg-warning {
    &~.kanban-drag {
      background-color: rgba($warning-color, 0.3) !important;
    }
  }

  &.bg-success {
    &~.kanban-drag {
      background-color: rgba($success-color, 0.3) !important;
    }
  }
}

.kanban-container {
  display: contents;

  footer {
    padding: 12px 6px;
    text-align: center;

    .btn {
      margin-bottom: 0;
    }
  }
}

.kanban-board {
  margin-bottom: 20px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  overflow: hidden;
  width: calc(100% / 4 - 30px) !important;
  cursor: pointer;

  @media (max-width:1500px) and (min-width:768px) {
    width: calc(100% / 2 - 30px) !important;
  }

  @media (max-width:767px) {
    width: calc(100% / 1 - 30px) !important;
  }

  @media (max-width:575px) {
    width: calc(100% / 1 - 20px) !important;
  }

  .kanban-drag {
    background: rgba($main-body-color, 0.9);

    .itemform {
      .form-group {
        &:last-child {
          padding-top: 12px;
        }
      }
    }
  }
}

.kanban-item {
  margin-bottom: 20px;
  border: 1px solid var(--chart-progress-light);
  border-radius: 5px;
  padding: 18px;
  position: relative;
  display: block;
  background-color: $white;

  .kanban-box {
    .badge {
      line-height: 1.7;
    }
  }

  p {
    opacity: 0.7;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .list {
    display: inline-block;
    opacity: 0.4;

    li {
      display: inline-block;
      font-size: 12px;

      i {
        margin-right: 10px;
      }

      +li {
        border-left: 1px dotted $dark-gray;
        padding-left: 6px;
        margin-left: 6px;

        [dir="rtl"] & {
          border-left: unset;
          border-right: 1px dotted $dark-gray;
          padding-left: unset;
          padding-right: 6px;
          margin-left: unset;
          margin-right: 6px;
        }
      }
    }
  }

  .common-f-start {
    li {
      [class*="bg-lighter-"] {
        font-size: 12px;
      }

      +li {
        margin-left: -22px;

        [dir="rtl"] & {
          margin-left: unset;
          margin-right: -22px;
        }
      }
    }
  }

  .kanban-box {

    &+.kanban-box {
      margin-top: 20px;
    }

    .common-align {
      img {
        height: calc(34px + (40 - 34) * (100vw - 320px) / (1920 - 320));
      }
    }

    .badge {
      font-weight: 400;
    }

    h6 {
      margin: 10px 0 10px;
      @include pixelstrap-ellipsis;
    }

    .dropdown {
      display: inline-block;
      right: 10px;
      position: absolute;
      top: 20px;

      .dropdown-item {
        font-size: 13px;
        font-weight: 500;
        padding: 5px 10px;
        text-align: left;
      }

      svg {
        height: 14px;
      }
    }

    .d-flex {
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width:1636px) {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px;
      }
    }

    .dropdown-toggle {
      &:after {
        content: none;
      }
    }

    .customers ul li+li {
      margin-left: -10px;
    }

    .date {
      color: rgba($badge-light-color, 0.8);
    }
  }
}

footer {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 600px) {

  .kanban-board .kanban-drag {
    padding: 10px 15px;
  }

  .kanban-container .kanban-board {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

@media only screen and (max-width: 480px) {
  .jkanban-container {
    .card-body {
      padding: 20px 15px !important;
    }
  }

  .kanban-board .kanban-drag {
    padding: 10px 15px;
  }
}

/**=====================
   3.37 Kanban CSS ends
==========================**/