/**=====================
    3.15 Dashboard_7 Start
==========================**/
.increase-content {
    &.pe-0 {
        [dir="rtl"] & {
            padding-right: 30px !important;
            padding-left: 0px !important;
        }
    }
}

.knowledge-wrapper {
    div {
        width: 80%;

        p {
            @media (max-width:1403px) {
                display: block;
                display: -webkit-box;
                max-width: 100%;
                height: 65px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        button {
            position: absolute;
            padding: 5px 16px;
            bottom: 63px;

            @media (max-width:1700px) {
                padding: 5px 8px;
            }

            @media (max-width:1600px) {
                padding: 4px;
            }
        }
    }

    .knowledgebase-wrapper {
        width: 75%;
        margin-top: -24%;
        margin-left: auto;

        [dir="rtl"] & {
            margin-left: unset;
            margin-right: auto;
            transform: scaleX(-1);
        }
    }
}

.school-performance-wrap {
    @media (max-width:1399px) {
        margin-bottom: -23px;
    }

    @media (max-width:1200px) and (min-width:768px) {
        margin-bottom: -53px;
    }
}

.count-student {
    .school-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .school-header {
            i {
                font-size: 12px;
                font-weight: 600;
            }

            p {
                line-height: 1.4;
            }
        }

        .school-body {
            img {
                width: calc(62px + (70 - 62) * ((100vw - 320px) / (1920 - 320)));
                position: relative;

                @media (max-width:622px) {
                    width: 50px;
                }
            }

            .right-line {
                position: absolute;
                bottom: 0;
                right: 0;

                [dir="rtl"] & {
                    right: unset;
                    left: 0;
                }

                img {
                    width: 100%;
                    height: 100%;

                    [dir="rtl"] & {
                        transform: scaleX(-1);
                    }
                }
            }
        }
    }
}

.income-wrapper {
    @media (max-width:1400px) and (min-width:754px) {
        margin-bottom: -26px;
    }

    @media (max-width:753px) {
        margin-bottom: -65px;
    }

    ul {
        display: flex;
        justify-content: space-between;

        @media (max-width:1735px) {
            justify-content: flex-start;
            gap: 10px;
            flex-wrap: wrap;

            @media (max-width:1199px) and (min-width: 339px) {
                gap: 15px;
            }

            @media (max-width:338px) {
                gap: 0;
            }
        }

        @media (max-width: 395px) {
            flex-wrap: nowrap;
        }

        li {
            text-align: center;

            +li {
                border-left: 1px dashed rgba($badge-light-color, 30%);

                [dir="rtl"] & {
                    border-left: unset;
                    border-right: 1px dashed rgba($badge-light-color, 30%);
                }

                @media (max-width:1735px) {
                    border-left: unset;

                    [dir="rtl"] & {
                        border-right: unset;
                    }
                }
            }

            h6 {
                display: inline-block;
            }

            .income-dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 6px;

                [dir="rtl"] & {
                    margin-left: 6px;
                    margin-right: unset;
                }
            }
        }
    }
}

.performance-wrap {
    @media (max-width:1678px) {
        margin-bottom: -23px;
    }
}

.performance-right {
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 540px) {
        display: none;
    }

    p {
        color: rgba($theme-body-font-color, 80%);
        font-weight: 500;
    }

    i {
        font-size: 14px;
    }
}

.attendance-card {
    display: flex;
    padding: 15px;
    border-radius: 5px;
    gap: 15px;
    align-items: center;


    @media (max-width:1700px) {
        gap: 10px;
    }

    @media (max-width: 480px) {
        align-items: flex-start;
    }

    .left-overview-content {
        display: flex;
        gap: 15px;
        align-items: center;

        .svg-box {
            width: 43px;
            height: 43px;
            background-color: var(--white);
            box-shadow: 0px 71.2527px 51.5055px rgb(229 229 245 / 19%), 0px 42.3445px 28.0125px rgb(229 229 245 / 15%), 0px 21.9866px 14.2913px rgb(229 229 245 / 13%), 0px 8.95749px 7.16599px rgb(229 229 245 / 10%), 0px 2.03579px 3.46085px rgb(229 229 245 / 6%);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .right-overview-content {
        display: flex;
        gap: 25px;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 43px - 15px);

        h6 {
            padding-bottom: 7px;
        }

        span {
            line-height: 18.69px;
        }

        @media (max-width:1700px) {
            gap: 18px
        }

        @media (max-width: 480px) {
            flex-wrap: wrap;
            gap: 7px;
        }

        .marks-count {
            display: flex;
            gap: 23px;
            align-items: center;

            sub {
                bottom: -0.8px;
            }

            @media (max-width:1700px) {
                gap: 12px;
                flex-wrap: wrap;
            }

            @media (max-width: 480px) {
                gap: 14px;
            }
        }
    }
}

.default-inline-calender {
    .school-calender {
        .main-inline-calender {
            .flatpickr-rContainer {
                .flatpickr-days {
                    .dayContainer {
                        .flatpickr-day {
                            height: 40px;

                            @media (max-width:1199px) {
                                height: 36px;
                            }
                        }
                    }
                }
            }

            .flatpickr-calendar {
                .flatpickr-months {
                    .flatpickr-current-month {
                        @media (max-width:1671px) {
                            font-size: 15px;
                        }

                        @media (max-width:1514px) {
                            font-size: 14px;
                            width: 85%;
                            left: 8.5%;
                        }
                    }
                }
            }
        }
    }
}

// Student leader
.student-leader-wrapper {
    .student-leader-content {
        display: flex;
        gap: 10px;
        padding: 10px;
        align-items: center;
        border-bottom: $card-border-color;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width:1525px) {
            gap: 6px;
        }

        img {
            width: 24px;
            height: 27px;
        }

        .leader-img {
            width: 35px;
            height: 35px;
            border-radius: 5px;

            @media (max-width:1700px) {
                display: none;
            }
        }

        .leader-content-height {
            span {
                line-height: 18.69px;

                @media (max-width:1510px) {
                    display: none;
                }
            }
        }

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

// Today's task
.task-table {
    .main-task {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 8px;

        .task-progress {
            width: 100px;
            height: 6px;
        }
    }

    .recent-table {
        &.task-table {
            tr {
                td {
                    &:first-child {
                        min-width: 312px;
                    }

                    &:nth-child(2) {
                        min-width: 110px;
                    }

                    &:last-child {
                        padding-right: 8px;
                        min-width: 34px;

                        [dir="rtl"] & {
                            padding-right: unset;
                            padding-left: 8px;
                        }
                    }
                }
            }
        }

        table {
            tr {
                &:last-child {
                    td {
                        padding-bottom: 12px;
                    }
                }
            }
        }
    }

    .main-task-wrapper {
        tr {
            td {
                cursor: pointer;

                .form-check {
                    &.checkbox-width {
                        padding-left: 0;
                    }
                }

                h6 {
                    @media (max-width:1415px) and (min-width:1200px) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 165px;
                    }

                    @media (max-width:585px) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 250px;
                    }
                }
            }
        }

        .task-icons {
            display: flex;
            align-items: center;
            gap: 12px;

            li {
                &:last-child {
                    border-left: 1px solid $gray-60;
                    padding-left: 10px;
                    display: flex;
                    gap: 8px;

                    [dir="rtl"] & {
                        border-left: unset;
                        border-right: 1px solid $gray-60;
                        padding-left: unset;
                        padding-right: 10px;
                    }
                }
            }
        }

        .square-white {
            width: 34px;
            height: 34px;
            border-radius: 2px;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 71.2527px 51.5055px rgba(229, 229, 245, 0.189815), 0px 42.3445px 28.0125px rgba(229, 229, 245, 0.151852), 0px 21.9866px 14.2913px rgba(229, 229, 245, 0.125), 0px 8.95749px 7.16599px rgba(229, 229, 245, 0.0981481), 0px 2.03579px 3.46085px rgba(229, 229, 245, 0.0601852);

            i {
                color: rgba($badge-light-color, 50%);
                font-size: 17px;
            }

            @media (max-width:575px) {
                width: 30px;
                height: 30px;

                i {
                    font-size: 14px;
                }
            }
        }

        .fill-clock {
            fill: rgba($badge-light-color, 50%);
        }

        button {
            padding: 4px 6px 4px 6px;
        }
    }

    .open-options {
        .dropdown-toggle {
            &::after {
                content: '';
                display: none;
            }
        }

        .dropdown-menu {
            padding: 9px 14px;
            background-color: var(--light2);

            &.show {
                display: flex;
                gap: 8px;
                min-width: 0;
            }

            li {
                .dropdown-item {
                    &:hover {
                        i {
                            color: $theme-body-sub-title-color;
                        }
                    }
                }

                .dropdown-item {
                    &:hover {
                        background-color: $lighter-white;
                    }
                }
            }
        }
    }

    .icons-box {

        @media (max-width:1770px) {
            display: none;

        }
    }

    .icons-box-2 {

        @media (max-width:1920px) {
            display: none;
        }

        @media (max-width:1770px) {
            display: table-cell;
        }
    }
}

// Notice-board
.notice-board {
    ul {
        li {
            padding-bottom: 29px;

            @media (max-width:1416px) {
                padding-bottom: 16px;
            }

            h6 {
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 500;
                line-height: 18.69px;
            }

            p {
                font-size: 12px;
                font-weight: 400;

                @media (max-width:1477px) {
                    &:last-child {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 126px;
                    }
                }
            }
        }
    }
}

.attendance-data {
    ul {
        &.balance-data {
            @media (max-width:1660px) {
                position: relative;
                top: 0;
                left: 0;
                display: inline-flex;
            }
        }
    }
}

.attendance-student-data {
    margin: 0 0px -20px -15px;
}

// Pie chart
.attendance-chart {
    display: flex;
    justify-content: center;

    @media (max-width:767px) {
        margin-bottom: 12px;
    }
}

.text-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.apexcharts-legend-series {
    @media (max-width:1515px) {
        margin: 5px 6px !important;
    }
}

.apexcharts-legend {
    @media (max-width:1870px) {
        [dir="rtl"] & {
            margin-right: -20px;
        }

    }

    @media (max-width:1870px) {
        [dir="rtl"] & {
            margin-right: -47px;
        }
    }

    @media (max-width:1515px) {
        [dir="rtl"] & {
            margin-right: -27px;
        }
    }
}

.unpaid-fees-table {
    .recent-table {
        &.table-responsive {
            div.dt-container {
                .dt-layout-row {
                    &:last-child {
                        @media (max-width:1625px) {
                            flex-direction: column;
                        }
                    }

                    .dt-layout-cell {
                        .dt-search {
                            @media (max-width:1199px) {
                                position: relative;
                                top: 4px;
                                left: 20px;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }

            table {

                thead,
                tbody {

                    th,
                    td {
                        min-width: auto;
                        color: var(--body-font-color);

                        &:first-child {
                            padding-left: 20px;
                        }

                        &:last-child {
                            padding-right: 20px;
                        }

                        a {
                            color: var(--body-font-color);
                        }

                        &:nth-child(3) {
                            @media (max-width:1732px) and (min-width:1200px) {
                                display: none;
                            }
                        }

                        &:nth-child(4) {
                            @media (max-width:1590px) and (min-width:1200px) {
                                display: none;
                            }
                        }

                        &:nth-child(2) {
                            @media (max-width:1595px) and (min-width:1200px) {
                                .common-align {
                                    img {
                                        display: none;
                                    }
                                }
                            }
                        }

                        &:nth-child(5) {
                            @media (max-width:1294px) and (min-width:1200px) {
                                display: none;
                            }
                        }

                        @media (max-width:1199px) {
                            &:nth-child(2) {
                                min-width: 160px;
                            }

                            &:last-child {
                                min-width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.top-student-table {
    .recent-table {
        &.table-responsive {
            div.dt-container {
                .dt-layout-row {
                    &:last-child {
                        @media (max-width:1625px) {
                            flex-direction: column;
                        }
                    }

                    .dt-layout-cell {
                        .dt-search {
                            @media (max-width:1199px) {
                                position: relative;
                                top: 4px;
                                left: 20px;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }

        table {

            thead,
            tbody {

                th,
                td {
                    min-width: auto;
                    color: var(--body-font-color);

                    &:first-child {
                        padding-left: 20px;
                    }

                    &:last-child {
                        padding-right: 20px;
                    }

                    a {
                        color: var(--body-font-color);
                    }

                    &:nth-child(2) {
                        @media (max-width:1673px) and (min-width:1200px) {
                            display: none;
                        }
                    }

                    &:nth-child(3) {
                        @media (max-width:1595px)and (min-width:1200px) {
                            .common-align {
                                img {
                                    display: none;
                                }
                            }
                        }
                    }

                    &:nth-child(6) {
                        @media (max-width:1434px)and (min-width:1200px) {
                            display: none;
                        }
                    }

                    &:nth-child(4) {
                        @media (max-width:1294px)and (min-width:1200px) {
                            display: none;
                        }
                    }

                    @media (max-width:1199px) {
                        &:nth-child(3) {
                            min-width: 150px;
                        }
                    }
                }
            }
        }
    }
}

.new-enroll-student {
    .recent-table {
        &.table-responsive {
            div.dt-container {
                .dt-layout-row {
                    .dt-layout-cell {
                        .dt-search {
                            @media (max-width:1530px) {
                                position: relative;
                                top: 4px;
                                left: 20px;
                                margin-bottom: 20px;
                                width: 75%;
                            }
                        }
                    }

                    &:last-child {
                        @media (max-width:1625px) {
                            flex-direction: column;
                        }
                    }
                }
            }
        }

        table {

            thead,
            tbody {

                th,
                td {
                    min-width: auto;
                    color: var(--body-font-color);

                    &:first-child {
                        padding-left: 20px;
                    }

                    &:last-child {
                        padding-right: 20px;
                    }

                    a {
                        color: var(--body-font-color);
                    }

                    &:nth-child(4) {
                        @media (max-width:1830px) and (min-width:1200px) {
                            display: none;
                        }
                    }

                    &:nth-child(2) {
                        @media (max-width:1607px) and (min-width:1200px) {
                            display: none;
                        }
                    }

                    &:nth-child(3) {
                        @media (max-width:1530px) and (min-width:1200px) {
                            display: none;
                        }
                    }

                    @media (max-width:1199px) {
                        &:first-child {
                            min-width: 154px;
                        }

                        &:last-child {
                            min-width: 124px;
                        }
                    }
                }
            }
        }
    }
}

.std-class-chart {
    .apexcharts-legend {
        gap: 35px !important;

        @media (max-width:1530px) and (min-width:1411px) {
            gap: 8px !important;
        }

        @media (max-width:1410px) {
            gap: 0 !important;
        }
    }

    @media (max-width:1610px) and (min-width:1531px) {
        margin-block: 16px;
    }

    @media (max-width:1530px) and (min-width:1405px) {
        margin-block: 52px;
    }

    @media (max-width:1404px) and (min-width:1211px) {
        margin-block: 64px;
    }

    @media (max-width:1210px) and (min-width:1200px) {
        margin-block: 80px;
    }
}

.student-header {
    .card-header-right-icon {
        @media (max-width:1350px) and (min-width:1200px) {
            display: none;
        }

        @media (max-width:650px) and (min-width:576px) {
            display: none;
        }
    }
}

/**=====================
   3.15 Dashboard_7 Ends
==========================**/