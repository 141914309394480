/**=====================
    3.19 Dashboard_11 Start
==========================**/

.widget-11 {
    background-image: url(/assets/images/dashboard/widget-bg.png);
    background-size: cover;
    margin-bottom: 25px;

    .card-body {
        padding: 20px 19px;

        .common-align {
            gap: 15px;
        }

        .analytics-tread {
            min-width: calc(48px + (58 - 48) * ((100vw - 320px) / (1920 - 320)));
            min-height: calc(48px + (58 - 48) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width:1705px) and (min-width:1200px) {
                min-width: 45px;
                min-height: 45px;
            }

            svg {
                width: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
                height: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));

                @media (max-width:1705px) and (min-width:1200px) {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        >div {
            span {
                @media (max-width:1835px) and (min-width:1641px) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 100px;
                    line-height: 1;
                }

                @media (max-width:1277px) and (min-width:1200px) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 100px;
                    line-height: 1;
                }
            }
        }
    }
}

.attendance-table {
    &.recent-table {
        div.dt-container {
            .dt-layout-row {
                .dt-search {
                    @media (max-width:1430px) and (min-width:1400px) {
                        display: none;
                    }

                    @media (max-width:1399px) {
                        position: relative;
                        top: 4px;
                        left: 20px;
                        margin-bottom: 20px;
                        width: 90%;
                    }
                }

                &:last-child {
                    .dt-info {
                        @media (max-width:1747px) and (min-width:1400px) {
                            display: none;
                        }
                    }

                    @media (max-width:1399px) {
                        flex-direction: column;
                    }
                }
            }
        }

        table {

            thead,
            tbody {
                tr {

                    th,
                    td {
                        a {
                            color: var(--body-font-color);
                        }

                        color: var(--body-font-color);
                        min-width: auto;

                        &:first-child {
                            padding-left: 20px;
                        }

                        &:last-child {
                            padding-right: 20px;
                        }

                        &:nth-child(2) {
                            @media (max-width:1656px) and (min-width:1200px) {
                                display: none;
                            }
                        }

                        .badge {
                            padding: 4px 7px;
                            font-weight: 400;
                            font-size: 14px;
                            min-width: 70px;
                        }

                        @media (max-width:1199px) {
                            &:first-child {
                                min-width: 120px;
                            }

                            &:nth-child(2) {
                                min-width: 108px;
                            }

                            &:nth-child(3) {
                                min-width: 104px;
                            }

                        }
                    }
                }
            }
        }
    }
}

.bhd-user-cards {
    background-image: url(/assets/images/dashboard-11/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.birthday-cards {
    .swiper {
        width: 100%;
        height: 100%;
        margin-block: 46px;

        @media (max-width:1447px) and (min-width:1400px) {
            margin-block: 60px;
        }

        @media (max-width:1399px) and (min-width:646px) {
            margin-block: 48px;
        }

        @media (max-width:645px) {
            margin-block: 35px;
        }

        .swiper-slide {
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .birthday-usercard {
                text-align: center;

                .btd-user {
                    display: inline-block;
                    padding: 8px;
                    background-image: url(/assets/images/dashboard-11/ring.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 100%;
                    position: relative;
                    margin-bottom: 12px;

                    img {
                        height: 80px;
                        border-radius: 50%;

                        @media (max-width:1447px) and (min-width:646px) {
                            height: 60px;
                        }

                        @media (max-width:645px) {
                            height: 50px;
                        }
                    }

                    .verified-user {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid $white;
                        background-color: $success-color;
                        right: 0;
                        bottom: 12px;
                        border-radius: 100%;

                        i {
                            font-size: 8px;
                            color: $white;
                        }

                        @media (max-width:645px) {
                            width: 16px;
                            height: 16px;
                            bottom: 6px;

                            i {
                                font-size: 6px;
                            }
                        }
                    }
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 26px;
            height: 23px;
            background-color: rgba($white, 0.2);
            border-radius: 2px;

            &::after {
                font-size: 10px;
                font-weight: bold;
                color: $white;
            }
        }
    }

    .send-wishes {
        background-color: rgba($white, 0.15);
        padding: 14px 23px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: fit-content;
        margin: auto;

        @media (max-width:1579px) {
            padding: 10px 16px;
        }

        span {
            color: $white;
        }

        i {
            color: $white;
        }
    }

    h5,
    h6 {
        color: $white;
    }
}

.project-analytics {
    .analytics-data {
        .balance-data {
            display: inline-flex;

            @media (max-width:1680px) {
                display: none;
            }
        }
    }

    .project-analytics-wrapper {
        margin: 0 0 -20px -10px;
    }
}

.app-overview-wrapper {
    margin: 0 -10px -20px -10px;
}

.project-finish-card {

    .project-left-content {

        .progress {
            background: linear-gradient(180.36deg, #CFDCE8 -32.82%, #FFFFFF 99.69%);
            border-radius: 20px;
            height: 14px;
            padding: 2px 4px;
            background-color: transparent;

            .progress-bar {
                background-color: $transparent-color;

                .common-f-start {
                    .progress-box {
                        width: 100%;
                        height: 16px;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        overflow: hidden;
                        background-color: transparent;
                        border-radius: 50px;

                        >div {
                            width: 3px;
                            min-width: 3px;
                            max-width: 3px;
                            height: 170%;
                            background-color: var(--theme-default);
                        }
                    }
                }
            }
        }

        >p {
            margin-bottom: 19px;

            @media (max-width:1517px) and (min-width:768px) {
                display: -webkit-box;
                height: 48px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .growth-info {
            margin-bottom: 31px;

            @media (max-width:897px) and (min-width:768px) {
                margin-bottom: 16px;
            }

            p {
                margin-bottom: 19px;
            }
        }
    }

    .project-progress {
        span {
            margin-bottom: 7px;

            @media (max-width:792px) and (min-width:768px) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 130px;
            }
        }
    }

    .project-right-content {
        position: absolute;
        bottom: -60px;
        right: -16px;
        animation: bounce-effect 3s infinite ease-in;

        @media (max-width:1680px) and (min-width:1600px) {
            bottom: -52px;
        }

        @media (max-width:1599px) and (min-width:1400px) {
            bottom: -50px;
        }

        @media (max-width:1399px) and (min-width:1200px) {
            bottom: -75px;
        }

        @media (max-width:1199px) and (min-width:1071px) {
            bottom: -63px;
        }

        @media (max-width:1070px) and (min-width:898px) {
            bottom: -55px;
        }

        @media (max-width:897px) and (min-width:768px) {
            bottom: -50px;
        }

        @media (max-width:767px) and (min-width:641px) {
            bottom: -83px;
        }

        @media (max-width:640px) and (min-width:501px) {
            bottom: -72px;
        }

        @media (max-width:500px) {
            bottom: -57px;
        }
    }

    @media (max-width:575px) {
        background-color: rgba(var(--light-shade-primary), 0.3);
        overflow: hidden;

        .project-left-content {
            position: relative;
            z-index: 1;
        }

        .project-right-content {
            filter: opacity(0.4);
            transform: translate(50px, -50px);
            animation: unset;
            z-index: 0;
            right: 50px;
            bottom: -140px;

            @media (max-width:440px) {
                filter: opacity(0.1);
            }
        }
    }
}

.leave-request-table {
    &.table-responsive {
        div.dt-container {
            .dt-layout-row {
                .dt-layout-cell {
                    .dt-search {
                        @media (max-width:1635px) {
                            display: none;
                        }
                    }
                }
            }
        }

        .table {

            thead,
            tbody {

                th,
                td {
                    min-width: auto;
                    padding: 12px 8px;

                    &:first-child {
                        padding-left: 20px;

                        @media (max-width:1779px) and (min-width:1200px) {
                            img {
                                display: none;
                            }
                        }
                    }

                    &:last-child {
                        padding-right: 20px;
                    }

                    @media (max-width:1635px) and (min-width:1400px) {
                        &:last-child {
                            display: none;
                        }

                        &:nth-child(2) {
                            padding-right: 20px;
                        }
                    }

                    .img-content-box {
                        a {
                            color: var(--body-font-color);
                        }
                    }

                    @media (max-width:1114px) {
                        &:first-child {
                            min-width: 212px;
                        }

                        &:nth-child(2) {
                            min-width: 114px;
                        }
                    }
                }
            }

            tbody {
                .common-align {
                    .approval-box {
                        width: 30px;
                        height: 30px;
                        border-radius: 2px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.interviewer-users {
    ul {
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 18px;
            gap: 6px;

            @media (max-width:1399px) and (min-width:576px) {
                padding: 14px 18px;
            }

            &:nth-child(odd) {
                background-color: var(--light2);
            }

            .common-flex {
                gap: 8px;

                @media (max-width:400px) {
                    flex: 1 1 50%;
                }

                h6 {
                    font-size: 14px;
                }
            }

            span {
                min-width: 100px;
            }

            img {
                height: 44px;
                border-radius: 50%;

                @media (max-width:400px) {
                    height: 35px;
                }
            }

            i {
                @media (max-width:1599px) and (min-width:1400px) {
                    display: none;
                }

                @media (max-width:810px) and (min-width:768px) {
                    display: none;
                }

                @media (max-width:340px) {
                    display: none;
                }
            }
        }
    }
}

.schedule-calender {
    .card-header-right-icon {
        .btn {
            border: 1px solid rgba($badge-light-color, 20%);
            color: var(--body-font-color);

            &:hover {
                background-color: var(--theme-default);
                color: $white;
            }
        }

        @media (max-width:1490px) and (min-width:1400px) {
            display: none;
        }
    }

    .header-top {
        @media (max-width:390px) {
            display: block;

            .card-header-right-icon {
                margin-top: 12px;
            }
        }
    }

    .schedule-wrapper {
        padding-bottom: 28px;
        border-bottom-color: rgba($badge-light-color, 10%);
        justify-content: center;

        @media (max-width:500px) {
            padding-bottom: 10px;
        }

        @media (max-width:1851px) {
            justify-content: flex-start;
            display: flex;
        }

        .nav-item {
            .nav-link {
                border-radius: 16px;
                border: unset;
                transition: all 0.2s ease;
                min-width: 62px;

                span {
                    color: rgba($badge-light-color, 80%);
                }

                h6 {
                    color: var(--body-font-color);
                }

                &.active {
                    background-color: var(--theme-default);
                    border-radius: 16px;
                    transition: all 0.2s ease;

                    span,
                    h6 {
                        color: $white;
                    }
                }
            }
        }
    }

    .tab-content {
        .activity-update {
            >li {
                padding-block: 12px;
                border-bottom: 1px solid var(--chart-border);

                @media (max-width:1399px) and (min-width:768px) {
                    width: 50%;
                }

                >div {
                    border-width: 4px !important;
                    border-radius: 6px 0px 0px 6px;
                    justify-content: space-between;
                    gap: 6px;

                    @media (max-width:1490px) and (min-width:1400px) {
                        flex-direction: column;
                        justify-content: flex-start;
                        padding-left: 20px;
                        align-items: flex-start !important;
                    }

                    @media (max-width:500px) {
                        flex-direction: column;
                        justify-content: flex-start;
                        padding-left: 20px;
                        align-items: flex-start !important;
                    }
                }

                .left-schedule {
                    margin-left: 14px;

                    @media (max-width:1490px) and (min-width:1400px) {
                        margin-left: 0;
                    }

                    @media (max-width:500px) {
                        margin-left: 0;
                    }

                    >span {
                        font-weight: 500;
                        color: var(--body-font-color);
                        margin-bottom: 6px;
                    }

                    .customers {
                        padding-right: 6px;

                        ul {
                            li {
                                +li {
                                    margin-left: -16px;
                                }

                                img {
                                    width: 35px !important;
                                }
                            }
                        }
                    }

                    .schedule-name {
                        border-left: 1px solid rgba($badge-light-color, 80%);
                        padding-left: 6px;

                        @media (max-width:1660px) {
                            display: none;
                        }
                    }
                }

                .right-schedule {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    align-items: center;

                    @media (max-width:500px) {
                        margin-top: 12px;

                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }

                    svg {
                        width: 18px;
                        height: 18px;

                        @media (max-width:1560px) and (min-width:1491px) {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    border-bottom: unset;
                    padding-bottom: unset;
                }

            }

            @media (max-width:1399px) and (min-width:768px) {
                display: flex;
                flex-wrap: wrap;

                >li {
                    flex: 0 0 50%;
                    margin-bottom: 10px;

                    .left-schedule,
                    .right-schedule {
                        flex: 1;
                    }

                    &:nth-child(5) {
                        border-bottom: unset;
                        padding-bottom: unset;
                        margin-bottom: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media (max-width:1399px) and (min-width:768px) {
        #thu {
            .activity-update {
                >li {
                    &:nth-child(3) {
                        border-bottom: unset;
                        padding-bottom: unset;
                        margin-bottom: 0;
                    }
                }
            }
        }

        #frd,
        #sun {
            .activity-update {
                >li {
                    &:first-child {
                        border-bottom: unset;
                        padding-bottom: unset;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.application-overview {
    .balance-data {
        @media (max-width:1799px) {
            display: none;
        }
    }
}

.employee-list-table {
    &.recent-table {
        div.dt-container {
            .dt-layout-row {
                &:last-child {
                    @media (max-width:1399px) {
                        flex-direction: column;
                    }
                }

                .dt-layout-cell {
                    .dt-search {
                        @media (max-width:1399px) {
                            position: relative;
                            top: 4px;
                            left: 20px;
                            margin-bottom: 20px;
                            width: 90%;
                            display: block;
                        }
                    }
                }
            }
        }

        table {

            thead,
            tbody {
                tr {

                    th,
                    td {
                        min-width: auto;

                        @media (max-width:1812px) and (min-width:1200px) {
                            &:nth-child(3) {
                                display: none;
                            }
                        }

                        @media (max-width:1480px) and (min-width:1200px) {
                            &:nth-child(4) {
                                display: none;
                            }
                        }

                        @media (max-width:1399px) and (min-width:1200px) {
                            &:nth-child(2) {
                                img {
                                    display: none;
                                }
                            }

                            &:nth-child(5) {
                                display: none;
                            }

                            &:last-child {
                                .square-white {
                                    width: 24px;
                                    height: 24px;

                                    svg {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                        }

                        @media (max-width:1199px) and (min-width:768px) {
                            &:nth-child(2) {
                                img {
                                    height: 26px;
                                }
                            }
                        }

                        @media (max-width:1199px) {
                            &:nth-child(2) {
                                min-width: 160px;
                            }

                            &:nth-child(3) {
                                min-width: 122px;
                            }

                            &:nth-child(5) {
                                min-width: 120px;
                            }

                            &:nth-child(6) {
                                min-width: 114px;
                            }

                            &:last-child {
                                .square-white {
                                    width: 24px;
                                    height: 24px;

                                    svg {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &:last-child {
                            cursor: pointer;
                        }
                    }
                }

                .square-white {
                    width: 34px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 2px;
                    background-color: var(--white);
                    box-shadow: 0px 0px 28px 6px rgba(235, 235, 235, 0.4);

                    svg {
                        fill: rgba($badge-light-color, 0.8);
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width:1640px) and (min-width:1400px) {
    .col-hr-6 {
        width: 50%;
        flex: 0 0 auto;
    }
}

/**=====================
    3.19 Dashboard_11 Start
==========================**/