/**=====================
   3.49 Progress CSS Start
==========================**/
.progress-showcase {
	.progress {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.sm-progress-bar {
	height: 8px;
}

.lg-progress-bar {
	height: 20px;
}

.progress-number {
	button {
		width: 2rem;
		height: 2rem;
		color: var(--white);
		border: unset;
	}

	.progress-wrapper {
		height: 1px;

		&.progress {
			background-color: var(--recent-dashed-border);
		}

		.progress-bar {
			background-color: var(--theme-default);
		}
	}
}

button {
	&.progress-btn {
		background-color: $light-chartist-grid-color;
		border-color: $light-chartist-grid-color;
		color: var(--body-font-color);
	}
}

.small-progressbar {
	position: relative;
	overflow: visible;

	&::after {
		position: absolute;
		content: '';
		top: -2px;
		right: -9px;
		background-color: $primary-color;
		width: 12px;
		height: 12px;
		border-radius: 100%;

		[dir="rtl"] & {
			left: -9px;
			right: unset;
		}
	}

	.progress-label {
		position: absolute;
		top: -26px;
		right: -6px;
		font-weight: 700;

		[dir="rtl"] & {
			right: unset;
			left: -6px;
		}
	}

	.animate-circle {
		position: absolute;
		top: -11px;
		right: -18px;
		height: 30px;
		width: 30px;
		border: 14px solid $primary-color;
		border-radius: 70px;
		animation: heart 1s ease-in-out;
		animation-iteration-count: infinite;

		[dir="rtl"] & {
			right: unset;
			left: -18px;
		}
	}
}

@keyframes heart {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	25% {
		transform: scale(.1);
		opacity: 1;
	}

	50% {
		transform: scale(.5);
		opacity: .3;
	}

	75% {
		transform: scale(.8);
		opacity: .5;
	}

	100% {
		transform: scale(1);
		opacity: 0;
	}
}

.progress-b-space {
	.progress {
		margin-bottom: 35px;
	}
}

// -------------------
.step-progress-wrapper {
	.step-progress-box {
		ul {
			overflow-x: auto;
			overflow-y: hidden;
		}
	}

	.progress-box {
		width: 100%;
		height: auto;
		@include pixelstrap-flex(flex, flex-start, center);
	}

	ul {
		display: flex;

		li {
			@include pixelstrap-flex(flex, center, center);
			list-style: none;
			flex-direction: column;
			margin: 0 30px;

			&:first-child {
				margin-left: 0;

				[dir="rtl"] & {
					margin-left: 30px;
					margin-right: 0;
				}
			}

			&:last-child {
				margin-right: 0;

				[dir="rtl"] & {
					margin-left: 0;
					margin-right: 30px;
				}
			}

			@media screen and (max-width:1580px) and (min-width:1200px) {
				&:first-child {
					[dir="rtl"] & {
						margin-left: 5px;
						margin-right: 0;
					}
				}

				&:last-child {
					[dir="rtl"] & {
						margin-left: 0;
						margin-right: 5px;
					}
				}
			}

			svg {
				color: $success-color;
				margin: 0 30px;
			}

			.p-step {
				svg {
					color: $white;
					margin: 0;
				}
			}

			h6 {
				letter-spacing: 1px;
				font-size: 14px;
				font-weight: bold;
				color: $success-color;
			}

			.p-step {
				height: 30px;
				width: 30px;
				border-radius: 50%;
				background-color: $success-color;
				margin: 16px 0 10px;
				display: grid;
				place-items: center;
				position: relative;
				cursor: pointer;

				svg {
					display: none;
				}

				p {
					margin: 0;
					color: $white;
				}
			}

			.active {
				background-color: $success-color;

				p {
					display: none;
				}

				svg {
					display: flex;
					z-index: 2;
					width: 14px;
					height: 14px;
				}
			}
		}
	}

	.p-step {
		&::after {
			content: "";
			position: absolute;
			width: 160px;
			height: 3px;
			background-color: transparent;
			right: 0;

			[dir="rtl"] & {
				right: unset;
				left: 0;
			}
		}
	}

	.first {
		&::after {
			width: 0;
			height: 0;
		}
	}

	li {
		.active {
			&::after {
				background-color: $success-color;
			}
		}
	}

	@media (max-width:1815px) and (min-width:1581px) {
		ul {
			li {
				margin: 0 15px;

				[dir="rtl"] & {
					&:first-child {
						margin-left: 15px;
						margin-right: unset;
					}

					&:last-child {
						margin-left: unset;
						margin-right: 15px;
					}
				}

				.p-step::after {
					width: 140px;
				}
			}
		}
	}

	@media (max-width:1408px) and (min-width:1200px) {
		ul {
			li {
				svg {
					margin: 0 28px;
				}
			}
		}
	}
}

/**=====================
   3.49 Progress CSS Ends
==========================**/