/**=====================
    3.38 Knowledgebase CSS start
==========================**/
.browse {
  .browse-articles {

    h6 {
      line-height: 1.6;
    }

    span {
      vertical-align: middle;

      &:nth-child(2) {
        padding-left: 20px;

        [dir="rtl"] & {
          padding-left: unset;
          padding-right: 20px;
        }
      }

      svg {
        width: 14px;
        height: 14px;
        vertical-align: text-top;
        position: absolute;
        left: 0;
        top: 0;

        [dir="rtl"] & {
          left: unset;
          right: 0;
        }
      }
    }

    h6 {
      padding-bottom: 10px;
      margin-bottom: 0px;
      border-bottom: 1px solid $light-gray;
      padding-left: 25px;

      [dir="rtl"] & {
        padding-left: unset;
        padding-right: 25px;
      }

      span {
        svg {
          @include pixelstrap-size(16px, 16px);
          vertical-align: text-top;
          top: 4px;
          left: 7px;

          [dir="rtl"] & {
            left: unset;
            right: 7px;
          }
        }
      }
    }

    ul {
      li {
        padding: 8px;
        border-radius: 8px;

        a {
          color: $theme-body-font-color;
          position: relative;
          display: flex;
          align-items: center;
          gap: 10px;
        }

        &:hover {
          background-color: $light;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .browse {
    div[class^="col-"] {
      &:nth-child(3) {
        margin-top: 30px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .browse {
    .browse-articles {
      ul {
        li {
          a {
            span {
              &:nth-child(2) {
                width: 158px;
                @include pixelstrap-ellipsis;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.badge {
  &.pull-right {
    padding-top: 4px;
  }
}

.articles-icons {

  svg {
    min-width: 18px;
  }
}


.d-flex {
  &.faq-widgets {
    .flex-grow-1 {
      flex: 1;
    }
  }
}

/**=====================
    3.38 Knowledgebase CSS End
==========================**/