/**=====================
    3.58 Timeline-v CSS Start
==========================**/

.timeline-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 12px;
}

.activity {
	.d-flex {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		.flex-grow-1 {
			h6 {
				font-weight: 600;
				margin-bottom: 30px;

				span {
					font-weight: normal;
					color: $light-text;
				}
			}

			p {
				width: 80%;
			}
		}
	}
}

@media only screen and (max-width: 1169px) {
	.landing-main {
		#cd-timeline::before {
			left: -39px;
		}
	}
}

.main-timeline {
	ul {
		&::before {
			left: 5px;

			[dir="rtl"] & {
				right: 5px;
				left: unset;
			}
		}

		@media (max-width:1399px) {
			&::before {
				left: 4px;
			}
		}

		li {
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}

.basic-timeline {
	&.dark-timeline {
		ul {
			.d-flex {
				p {
					&:last-child {
						@media (min-width:1200px) {
							width: calc(100px + (530 - 100) * ((100vw - 320px) / (1920 - 320)));
						}
					}
				}
			}
		}
	}
}

.square-timeline {
	position: relative;

	&::before {
		border: 1px dashed $gray-60;
		content: '';
		margin-left: 0px;
		position: absolute;
		top: 0;
		left: 18px;
		width: 1px;
		height: 100%;

		[dir="rtl"] & {
			margin-left: unset;
			margin-right: 0;
			left: unset;
			right: 18px;
		}
	}

	.timeline-event {
		position: relative;

		&:hover {
			.timeline-event-icon {
				transform: rotate(-105deg);
				background-color: $badge-light-color;
			}

			.timeline-event-wrapper {
				.timeline-thumbnail {
					box-shadow: inset 40em 0 0 0 $light-warning;
				}
			}
		}

		.timeline-event-wrapper {
			padding: 0 0 0 14px;
			position: relative;
			top: -7px;
			left: 38px;
			width: 95%;

			[dir="rtl"] & {
				left: unset;
				right: 38px;
				padding: 0 14px 0 0;
			}

			@media (max-width: 1200px) {
				width: calc(84% + (95 - 84) * ((100vw - 320px) / (1200 - 320)));
			}

			.designer-details {
				padding-top: 20px;
			}

			.timeline-thumbnail {
				transition: box-shadow 0.5s ease-in 0.1s;
				color: $dark-color;
				background-color: $light-primary;
				box-shadow: inset 0 0 0 0em $light-info;
				display: inline-block;
				margin-bottom: 12px;
				padding: 2px 25px;
			}

			.list-group-item-action {

				&:hover,
				&:active,
				&:focus {
					background-color: unset;
				}
			}

			>div {
				display: inline-block;
				width: 100%;

			}
		}

		.timeline-event-icon {
			transition: 0.2s ease-in;
			transform: rotate(45deg);
			background-color: $primary-color;
			outline: 6px solid $light-primary;
			display: block;
			margin: 0px;
			position: absolute;
			top: 0;
			left: 14px;
			width: 10px;
			height: 10px;

			[dir="rtl"] & {
				right: 14px;
				left: unset;
			}
		}
	}
}

// Horizontal timeline
.list-inline {
	display: flex;
	justify-content: center;

	&.events {
		.event-list {
			display: block;
			position: relative;
			text-align: center;
			padding-top: 70px;
			margin-right: 0;

			p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}

			.event-date {
				position: absolute;
				top: 38px;
				left: 0;
				right: 0;
				width: 75px;
				margin: 0 auto;
				border-radius: 4px;
				padding: 2px 4px;

				p {
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					display: -webkit-box;
					overflow: hidden;
					line-height: 1.5;
					margin-top: 7px;
				}
			}
		}
	}
}

.timeline-list {

	&.timeline-border {
		border-bottom: 1px solid $gray-60;
	}

	&.list-inline {
		padding-bottom: 0;

		&.events {

			.event-list {
				padding-top: 35px;

				&::before {
					content: '';
					height: 0;
					border-right: unset;
				}

				.event-date {
					top: 2px;

					p {
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						display: -webkit-box;
						overflow: hidden;
						line-height: 1.5;
						margin-top: 7px;
					}
				}

				.read-more-btn {
					position: relative;
					margin-bottom: 3px;
				}
			}
		}

		.vertical-line {
			height: 45px;
			width: 1px;
			margin: 0 auto;
			position: relative;
			border: 1px dashed $primary-color;
		}
	}
}

.list-group {
	&.main-lists-content {
		padding-top: 20px;
	}

	.list-group-item {
		.timeline-icon {
			@media (max-width:1218px) {
				display: none;
			}
		}
	}
}

.horizontal-timeline {
	@media (max-width: 1400px) {
		display: none;
	}
}

.rounded-timeline {
	.timeline {
		position: relative;
		width: 100%;
		padding: 0;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			width: 2px;
			background: $gray-60;
			top: 0;
			bottom: 0;
			left: 50%;
			margin-left: -1px;

			@media (max-width:1199px) {
				left: 75px;
			}
		}
	}

	.container {
		padding: 15px 30px;
		position: relative;
		background: inherit;
		width: 50%;
		margin: 0;

		@media (max-width:1199px) {
			width: 85%;
		}

		&::after {
			content: '';
			position: absolute;
			width: 16px;
			height: 16px;
			top: calc(50% - 8px);
			right: -8px;
			background: var(--white);
			border: 2px solid $gray-60;
			border-radius: 16px;
			z-index: 1;
		}

		&::before {
			content: '';
			position: absolute;
			width: 50px;
			height: 2px;
			top: calc(50% - 1px);
			right: 8px;
			background: $gray-60;
			z-index: 1;
		}

		.date {
			position: absolute;
			display: inline-block;
			top: calc(50% - 8px);
			text-align: center;
			font-size: 14px;
			font-weight: bold;
			color: var(--chart-text-color);
			text-transform: uppercase;
			letter-spacing: 1px;
			z-index: 1;

			@media (max-width:610px) {
				display: none;
			}
		}

		audio {
			@media (max-width:1300px) {
				width: 95%;
			}
		}

		.icon {
			position: absolute;
			display: inline-block;
			width: 40px;
			height: 40px;
			padding: 9px 0;
			top: calc(50% - 20px);
			background: var(--light2);
			border: 2px dashed var(--chart-text-color);
			border-radius: 40px;
			text-align: center;
			font-size: 18px;
			color: var(--chart-text-color);
			z-index: 1;
		}

		.content {
			padding: 20px 80px 20px 20px;
			background: var(--light2);
			position: relative;
			border-radius: 0 200px 200px 0;

			audio {
				@media (max-width:767px) {
					width: 95%;
				}
			}

			h2 {
				margin: 0 0 10px 0;
				font-size: 18px;
				font-weight: normal;
				color: var(--chart-text-color);
			}

			p {
				margin: 0;
				color: var(--body-font-color);

				@media (max-width:610px) {
					display: -webkit-box;
					max-width: 100%;
					margin: 0 auto;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.container.left {
		left: 0;

		.date {
			right: -75px;
		}

		.icon {
			right: 56px;
		}

		@media (max-width:1199px) and (min-width:768px) {
			left: 75px;

			&::before {
				left: 8px;
			}

			&::after {
				left: -8px;
			}

			.date {
				left: -75px;
				right: unset;
			}

			.icon {
				left: 56px;
			}

			.content {
				padding: 20px 20px 20px 80px;
				border-radius: 200px 0 0 200px;
			}
		}

		@media (max-width:767px) {
			left: 0;
		}
	}

	.container.right {
		left: 50%;

		&::after {
			left: -8px;
		}

		&::before {
			left: 8px;
		}

		.date {
			left: -75px;
		}

		.icon {
			left: 56px;
		}

		.content {
			padding: 20px 20px 20px 80px;
			border-radius: 200px 0 0 200px;
		}

		@media (max-width:1199px) {
			left: 75px;

			&::after {
				left: -8px;
			}
		}
	}

	@media (max-width: 767px) {
		.timeline {
			&::after {
				left: 90px;
			}
		}

		.container {
			width: 100%;
			padding-left: 120px;
			padding-right: 30px;
		}

		.container.right {
			left: 0%;

			&::after {
				left: 82px;
			}

			&::before {
				left: 95px;
				border-color: var(--theme-default);
			}

			.date {
				right: auto;
				left: 15px;
			}

			.icon {
				right: auto;
				left: 146px;
			}

			.content {
				padding: 30px 30px 30px 90px;
				border-radius: 200px 0 0 200px;
			}
		}

		.container.left {
			&::after {
				left: 82px;
			}

			&::before {
				left: 95px;
				border-color: var(--theme-default);
			}

			.date {
				right: auto;
				left: 15px;
			}

			.icon {
				right: auto;
				left: 146px;
			}

			.content {
				padding: 30px 30px 30px 90px;
				border-radius: 200px 0 0 200px;

			}
		}
	}

	@media (max-width:610px) {
		.timeline {
			&::after {
				left: 8px;
			}

			.container {
				padding-left: 30px;

				&::before,
				&::after {
					left: 0;
				}

				.icon {
					left: 50px;
				}

				.content {
					@media (max-width:475px) {
						padding: 30px 30px 30px 75px;
					}
				}
			}
		}
	}

	@media (max-width:475px) {
		.timeline {
			.container {
				padding-right: 0;
			}
		}
	}

	@media (max-width:440px) {
		.timeline {
			padding-top: 20px;

			.container {
				padding-block: 40px;
				padding-left: 0;
				z-index: 1;

				&::before {
					display: none;
				}

				.icon {
					top: -20px;
					left: 45%;
				}

				&::after {
					display: none;
				}

				.content {
					padding: 16px !important;
					border-radius: 10px !important;
				}
			}

			&::after {
				left: 50%;
			}
		}
	}

	@media (max-width:390px) {
		.timeline {
			.container {
				.icon {
					left: 44%;
				}
			}
		}
	}

	@media (max-width:335px) {
		.timeline {
			.container {
				.icon {
					left: 42%;
				}

				audio::-webkit-media-controls-enclosure {
					border-radius: 0;
					background-color: transparent;
				}

			}
		}
	}
}

// Animated timeline 
.animated-timeline {
	width: 100%;
	min-height: 100vh;
	height: 100%;
	color: var(--chart-text-color);

	.timeline-block {
		z-index: 1;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			height: calc(100% - 40px);
			width: 1px;
			border: 1px dashed $theme-body-sub-title-color;
			opacity: 0.4;
			left: 50%;
			margin-top: calc(45px + (55 - 45) * ((100vw - 320px) / (1920 - 320)));
		}

		.title {
			text-align: center;
			transition: all 0.8s ease-in-out;
			font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
			line-height: 1.2;
			font-weight: 500;
			color: $badge-light-color;

			&.non-focus {
				font-size: 24px;
				line-height: 34px;
				transition: all 0.8s ease-in-out;
			}
		}

		.timeline-event {
			background-color: rgba($light-color, 0.8);
			padding: 15px;
			margin: 30px 20px;
			width: 300px;
			position: relative;
			border-radius: 4px;
			transition: all 0.3s ease-in-out;
			border: 1px dashed $dark-color-light;

			&:before {
				content: "";
				position: absolute;
				top: calc(50% - 8px);
				left: -27px;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				background-color: $gray-60;
			}

			.timeline-desc {
				h6 {
					color: $badge-light-color;
				}

				span {
					color: $font-gray-color;
				}
			}

			&:nth-child(odd) {
				margin-left: calc(50% + 20px);
			}

			&:nth-child(even) {
				margin-left: calc(50% - 321px);

				&:before {
					left: calc(100% + 16px);
				}
			}

			&.non-focus {
				background: rgba($light-color, 0.3);
				margin-top: 108px;
				transform: rotateZ(45deg);
				transition: all 0.8s ease-in-out;
				border: 1px dashed rgba($dark-color-light, 0.2);

				.timeline-desc {
					h6 {
						color: rgba($badge-light-color, 0.4);
					}

					span {
						color: rgba($font-gray-color, 0.2);
					}
				}

				&:before {
					opacity: 0;
				}
			}
		}
	}

	@media (max-width:767px) {
		.timeline-block {
			&::before {
				left: 18px;
			}

			.title {
				text-align: left;
			}

			.timeline-event {
				&::before {
					left: -49px;
				}

				&:nth-child(odd) {
					margin-left: calc(50% - 260px);
				}

				&:nth-child(even) {
					margin-left: calc(50% - 260px);

					&:before {
						left: unset;
						right: calc(100% + 33px);
					}
				}
			}
		}
	}

	@media (max-width:767px) {
		.timeline-block {
			.timeline-event {
				&:nth-child(even) {
					&::before {
						left: unset;
						right: calc(100% + 31px);
					}
				}

				&::before {
					left: -47px;
				}
			}
		}
	}

	@media (max-width:767px) {
		.timeline-block {
			.timeline-event {
				&:nth-child(odd) {
					margin-left: 58px;
				}

				&:nth-child(even) {
					margin-left: 58px;
				}
			}
		}
	}

	@media (max-width:440px) {
		.timeline-block {
			.timeline-event {
				width: auto;

				&::before {
					width: 12px;
					height: 12px;
					left: -45px;
				}

				&:nth-child(even) {
					&::before {
						right: calc(100% + 33px);
					}
				}
			}
		}
	}
}

/**=====================
    3.58 Timeline-v CSS Ends
==========================**/