/**=====================
    5.8 Rtl CSS Start
==========================**/
html[dir="rtl"] {
  ul {
    padding-right: 0;
  }

  .ms-auto {
    margin-left: unset !important;
    margin-right: auto !important;
  }

  .slider-wrapper {
    direction: ltr;
  }

  .f-right {
    float: left;
  }

  .ps-1 {
    padding-left: unset !important;
    padding-right: .25rem;
  }

  .tap-top {
    right: unset;
    left: 30px;
  }

  .pe-sm-3 {
    @media(min-width: 576px) {
      padding-right: unset !important;
      padding-left: 1rem !important;
    }
  }

  .offcanvas {
    .offcanvas-header {
      .btn-close {
        margin: calc(-.5 * var(--bs-offcanvas-padding-y)) auto calc(-.5 * var(--bs-offcanvas-padding-y)) calc(-.5 * var(--bs-offcanvas-padding-x));
      }
    }
  }


  .papernote-wrap {
    .note-labels {
      ul {
        gap: 9px;
      }
    }
  }

  .form-check-inline {
    margin-right: unset;
    margin-left: 1rem;
  }

  .knowledgebase-search {
    .form-inline {
      svg {
        left: unset;
        right: 20px;
      }
    }
  }

  .switch-showcase {
    .form-check {
      .form-check-input {
        float: left;
      }
    }
  }

  .alert {
    &.notify-alert {
      &.alert-theme {
        left: 20px !important;
        right: unset !important;
      }
    }
  }

  .pill-input-group span {
    &:last-child {
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
      border-top-left-radius: 1.3rem !important;
      border-bottom-left-radius: 1.3rem !important;
    }

    &:first-child {
      border-top-left-radius: unset !important;
      border-top-right-radius: unset !important;
      border-top-right-radius: 1.3rem !important;
      border-bottom-right-radius: 1.3rem !important;
    }
  }

  /*======= All-Borders-color css starts  ======= */

  $b-name: primary,
    secondary,
    success,
    danger,
    info,
    light,
    dark,
    warning;
  $b-color: $primary-color,
    $secondary-color,
    $success-color,
    $danger-color,
    $info-color,
    $light-color,
    $dark-color,
    $warning-color;

  @each $var in $b-name {
    $i: index($b-name, $var);

    .b-l-#{$var} {
      border-right: 1px solid nth($b-color, $i) !important;
      border-left: unset !important;
    }

    .border-l-#{$var} {
      border-right: 1px solid nth($b-color, $i) !important;
      border-left: unset !important;
    }

    .b-r-#{$var} {
      border-left: 1px solid nth($b-color, $i) !important;
      border-right: unset !important;
    }

    .border-r-#{$var} {
      border-left: 1px solid nth($b-color, $i) !important;
      border-right: unset !important;
    }
  }

  /*======= All-Borders-color css ends  ======= */

  /*====== Border width css starts ======*/
  $i: 1;

  @while $i<=10 {
    .border-#{$i} {
      border-width:#{$i}px !important;
    }

    $i: $i+1;
  }

  /*====== Border width css ends ======*/


  .page-wrapper {
    &.compact-wrapper {
      .page-body-wrapper {
        .sidebar-wrapper {
          text-align: right;

          .toggle-sidebar {
            right: unset;
            left: 30px;
          }

          .sidebar-main .sidebar-links {
            padding: 0;

            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    >li .badge {
                      left: 50px !important;
                      right: unset !important;
                    }
                  }
                }
              }
            }
          }

          &.close_icon {
            margin-right: 0;
            margin-left: unset;

            .logo-icon-wrapper {
              text-align: left;
            }

            ~.page-body,
            ~footer {
              margin-left: 0;
              margin-right: 80px;
              transition: 0.5s;
            }

            .sidebar-main {
              .sidebar-links {
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        >li {
                          a {
                            svg {
                              margin-left: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .sidebar-list {
                  .sidebar-title {
                    &.active {
                      ~.sidebar-submenu {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }

            .according-menu {
              display: none;
            }

            &:hover {
              .sidebar-main {
                .sidebar-links {
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          >li {
                            a {
                              svg {
                                margin-left: 13px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &:hover {
            transform: translate(0px);

            .sidebar-main {
              .sidebar-links {
                .sidebar-list {
                  .sidebar-title {
                    &.active {
                      ~.sidebar-submenu {
                        display: block !important;
                      }
                    }
                  }
                }

                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        >li {
                          a {
                            svg {
                              float: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .according-menu {
              display: block;
            }
          }

          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .sidebar-submenu {
                          &:after {
                            right: 30px;
                            left: unset;
                          }

                          >li {
                            a {
                              padding-right: 45px !important;
                              padding-left: unset !important;

                              &:after {
                                right: 26px;
                                left: unset;
                              }
                            }
                          }
                        }

                        .sidebar-link {
                          &::after {
                            border-radius: 0px 5px 5px 0px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .page-body {
          margin-left: unset;
          margin-right: 265px;
        }

        .sidebar-wrapper {

          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        a {
                          svg {
                            margin-right: unset;
                            margin-left: 15px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .according-menu {
          right: unset;
          left: 20px;

          i {
            transform: scaleX(-1);
          }
        }
      }

    }

    &.only-body {
      .page-header {
        .header-wrapper {
          .nav-right {
            right: unset;
            left: 30px;
          }
        }
      }
    }
  }

  // task page
  .date-details {
    >div {
      +div {
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }

  // Toasts
  .default-toasts {
    .toast-dark {
      .form-select {
        padding: 6px 12px 6px 36px !important;
      }
    }
  }

  .animated-toast {
    &.end-0 {
      right: unset !important;
      left: 0 !important;

      .toast-header {
        h6 {
          margin-right: unset !important;
          margin-left: auto !important;
        }
      }

      .btn-close {
        margin-left: unset;
        margin-right: 12px;
      }
    }
  }

  // contact page css
  .contact-editform {
    &.ps-0 {
      padding-left: 20px !important;
    }
  }

  .contacts-tabs {
    .nav-pills {
      border-right: unset;
      border-left: 1px solid $light-color;
    }
  }

  .contact-options {
    li {
      .btn-category {
        padding-left: unset !important;
        padding-right: 40px;
      }
    }
  }

  .list-persons {
    .profile-mail {
      .email-general {
        ul {
          padding-right: unset;
          padding-left: 20px;
        }
      }

      .d-flex {
        .flex-grow-1 {
          ul {
            li {
              +li {
                padding-left: unset;
                padding-right: 10px;
                margin-left: unset;
                margin-right: 5px;
                border-left: unset;
                border-right: 1px solid $light-color;
              }
            }
          }

          .main-contact-option {
            li {
              +li {
                padding-left: unset;
                padding-right: 10px;
                margin-left: unset;
                margin-right: 5px;
                border-left: unset;
                border-right: 1px solid rgba($gray-60, 0.6);
              }
            }
          }
        }
      }
    }
  }

  .history-details {
    i {
      transform: scale(-1);
    }
  }

  // Common form floating
  .form-floating {
    >label {
      left: unset;
      right: 0;
    }
  }

  // file manager css
  .file-content {
    .form-inline {
      i {
        padding-right: unset;
        padding-left: 10px;
      }
    }
  }

  .file-sidebar {
    .btn {
      svg {
        margin-right: unset;
        margin-left: 8px;
      }
    }
  }

  // Modal
  .modal {
    .modal-toggle-wrapper {
      .btn {
        svg {
          transform: scaleX(-1);
        }
      }
    }
  }

  // projects page css
  .create-project-form {
    .bootstrap-select {
      .filter-option {
        .filter-option-inner-inner {
          text-align: right;
        }
      }

      .dropdown-toggle {
        &::after {
          right: unset;
          left: 10px;
        }
      }
    }
  }

  [class*="title-line-"] {
    &::before {
      left: unset;
      right: 0;
      border-radius: 10px 0px 0px 10px;
    }
  }

  // Bootstrap select
  .bootstrap-select {
    .dropdown-toggle {
      &::after {
        left: 10px;
        right: unset;
      }
    }

    .filter-option {
      float: right;
      text-align: right;
    }

    .dropdown-menu {
      li {
        text-align: right !important;
      }
    }
  }

  // Project List
  .projects-wrapper {
    [class*='title-line-'] {
      &::before {
        left: unset;
        right: 12px;
      }
    }
  }

  .project-tabs {
    .nav {
      i {
        margin-left: 10px;
        margin-right: unset;
      }
    }

    &.common-space {
      .nav-tabs {
        .nav-item {
          .nav-link {

            svg {
              margin-left: unset;
              margin-left: 5px;
            }
          }

          @media (min-width:576px) {
            &:first-child {
              .nav-link {
                padding-right: 0;
                padding-left: 25px;
              }
            }

            &:last-child {
              .nav-link {
                padding-left: 0;
                padding-right: 25px;
              }
            }
          }
        }
      }
    }
  }

  // Project timeline
  .project-timeline {
    .notification {
      ul {
        li {
          span {
            .activity-profile {
              border-left: unset;
              border-right: 1px solid rgba($badge-light-color, 20%);
              padding-left: unset;
              padding-right: 10px;
              margin-left: unset;
              margin-right: 10px;

              img {
                margin-right: unset;
                margin-left: 6px;
              }
            }
          }
        }
      }
    }
  }

  .project-budget-wrapper {
    .form-check {
      &.form-switch {
        padding: 0;
      }

      .form-check-input {
        margin: 0;
      }
    }
  }

  .overall-budget {
    &.recent-table {
      table {

        thead,
        tbody {
          tr {

            th,
            td {
              &:first-child {
                padding-right: 20px !important;
                padding-left: unset !important;
              }

              &:last-child {
                padding-left: 20px !important;
                padding-right: unset !important;
              }
            }
          }
        }
      }
    }
  }

  .user-comment-wrapper {
    li {
      .flex-grow-1 {
        .btn {
          svg {
            transform: scaleX(-1);
          }
        }
      }

      +li {
        margin-right: 46px;
        margin-left: unset;
      }
    }
  }

  .common-project-header {
    h6 {
      margin-left: unset;
      margin-right: -46px;

      @media (max-width:1685px) and (min-width:320px) {
        margin-right: -54px;
        margin-left: unset;
      }

      @media (max-width:644px) {
        margin-right: 0;
        margin-left: unset;
      }
    }

    .common-align {
      &:last-child {
        .common-align {
          li {
            svg {
              margin-right: unset;
              margin-left: 6px;
            }

            &:first-child {
              padding-right: unset;
              padding-left: 15px;
            }

            +li {
              border-right: 1px solid rgba($badge-light-color, 80%);
              border-left: unset;
              padding-right: 15px;
              padding-left: unset;
            }

            @media (max-width:435px) {

              &:first-child {
                padding-left: 0px;
                padding-right: unset;
              }

              +li {
                border-right: unset;
                padding-right: 0;
                padding-left: unset;
              }
            }
          }
        }
      }
    }
  }

  .activity-wrapper {
    .tab-content {
      .activity-update {
        >li {
          border-right: 3px solid rgba($badge-light-color, 20%);
          border-left: unset;

          .flex-grow-1 {
            margin-left: unset;
            margin-right: 10px;
          }

          .flex-shrink-0 {
            text-align: start;

            svg {
              margin-right: unset;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }

  .cmt-box {
    i {
      transform: scaleX(-1);
    }
  }

  // Project pending
  .project-pending-table {
    &.recent-table {
      div.dt-container {
        .dt-layout-row {
          .dt-search {
            @media (min-width:641px) {
              left: 55px !important;
              right: unset !important;
            }
          }
        }
      }

      table {

        thead,
        tbody {
          tr {

            th,
            td {

              &:first-child {
                padding-right: 20px;
                padding-left: unset;
              }

              &:last-child {
                padding-left: 20px;
                padding-right: unset;
              }
            }
          }
        }
      }
    }
  }


  .project-list {
    .btn {
      float: left;
      margin-right: unset;
      margin-left: 5px;
    }

    ul {
      li {
        svg {
          margin-right: unset;
          margin-left: 5px;
        }
      }
    }
  }

  .scope-bottom-wrapper {
    .sidebar-left-icons {
      .nav-item {
        &::before {
          left: unset;
          right: 38px;

          @media (max-width:1839px) and (min-width:1661px) {
            left: unset;
            right: 30px;
          }

          @media (max-width:1660px) and (min-width:1400px) {
            left: unset;
            right: 35px;
          }

          @media (max-width:1399px) and (min-width:320px) {
            left: unset;
            right: 30px;
          }
        }

        .nav-link {
          padding: 12px 8px 12px 0;

          &.active {
            background: linear-gradient(-90deg, #7366FF -175.57%, rgba(115, 102, 255, 0) 65.16%);

            .absolute-border {
              &::before {
                left: unset;
                right: 0;
              }

              &::after {
                left: unset;
                right: -8px;
                border-top-right-radius: unset;
                border-bottom-right-radius: unset;
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
                transform: translate(50%, -50%);
              }
            }
          }
        }
      }
    }
  }

  .form-select {
    background-position: left 0.75rem center;
    padding: .375rem .75rem .375rem 2.25rem;
  }

  .customers {
    ul {
      li {
        +li {
          margin-left: unset;
          margin-right: -10%;
        }
      }
    }
  }

  .project-list {
    .border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 5px 0 5px 30px;
          }
        }
      }
    }
  }

  .project-box {
    .badge {
      right: unset;
      left: 30px;
    }
  }

  // seller details 
  .seller-details-wrapper {
    .accordion {
      .accordion-item {
        .accordion-button {
          &::after {
            margin-left: unset;
            margin-right: auto;
          }
        }
      }
    }
  }


  // kanban board css
  .jkanban-container {
    .kanban-container {
      .kanban-board {
        footer {
          margin: 0;
        }
      }
    }
  }

  .kanban-board {
    float: right;
  }

  .kanban-item {
    .kanban-box {
      .customers {
        ul {
          li {
            +li {
              margin-left: unset;
              margin-right: -10px;
            }
          }
        }
      }
    }

    .list {
      li {
        i {
          margin-right: unset;
          margin-left: 10px;
        }
      }
    }
  }

  //horizontal-sidebar
  .page-wrapper {
    &.horizontal-wrapper {
      .header-logo-wrapper {
        text-align: right;
      }

      .page-body-wrapper {
        .page-body {
          margin-right: 0;
        }

        footer {
          margin-right: 0;
        }
      }
    }

    &.material-type {
      .page-body-wrapper {
        .page-body {
          margin-right: auto;
        }
      }
    }
  }

  .page-header {
    .header-wrapper {
      .nav-right {
        >ul {
          >li {
            &:first-child {
              margin-right: 0;
            }
          }
        }

        &.right-header {
          ul {
            li {
              .profile-media {
                .flex-grow-1 {
                  margin-right: 15px;
                  margin-left: unset;
                }
              }
            }
          }
        }
      }

      .toggle-sidebar {
        margin-left: 2px;
        margin-right: unset;
      }
    }
  }

  // compact icon css
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .notification-dropdown {
            left: -20px !important;
            right: unset !important;

            @media (max-width: 575.98px) {
              left: 12px !important;
            }
          }
        }

        .search-full {
          input {
            padding-left: unset;
            padding-right: 60px;
            text-align: right;
          }

          .form-group {
            &:before {
              left: unset;
              right: 30px;
            }

            .close-search {
              right: unset;
              left: 0;
            }
          }
        }
      }
    }

    &.compact-sidebar {
      .toggle-sidebar {
        margin-left: 15px;
        margin-right: unset;
      }

      .page-header {
        margin-left: unset;
        margin-right: 150px;

        &.close_icon {
          margin-right: 0;
        }
      }

      .page-body-wrapper {
        .page-body {
          margin-left: unset;
          margin-right: 150px;
        }

        .footer {
          margin-left: unset;
          margin-right: 150px;
        }

        div.sidebar-wrapper {
          >div {
            text-align: right;
          }

          .sidebar-main {

            .sidebar-links {
              li {
                > {
                  .sidebar-submenu {
                    li {
                      a {
                        padding: 15px 40px 150px 15px;
                      }

                      .sidebar-submenu {
                        padding-left: unset;
                        padding-right: 20px;
                      }
                    }
                  }
                }

                a {
                  &.active {

                    ~.sidebar-submenu {
                      animation: fadeInRight 1000ms ease-in-out;
                    }
                  }
                }

                .sidebar-submenu {
                  right: 150px;
                  left: unset;

                  li {
                    a {
                      padding: 15px 40px 15px 15px;

                      .according-menu {
                        right: unset;
                        left: 20px;
                        transform: scaleX(-1);
                      }

                    }
                  }

                  >li {
                    >a {
                      &::after {
                        left: unset;
                        right: 20px;
                      }
                    }
                  }
                }
              }


              .sidebar-list {

                .badge {
                  left: unset;
                  right: 53%;
                }

                .sidebar-link {
                  text-align: center;
                }
              }
            }
          }

          &.close_icon {
            transform: translateX(150px);

            ~.page-body,
            ~.footer {
              margin-right: 0px;
            }
          }
        }
      }
    }

    &.compact-small {
      .page-header {
        margin-right: 90px;
      }

      .page-body-wrapper {
        div.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              li {

                .sidebar-submenu {
                  right: 90px;
                }
              }
            }
          }
        }

        .footer,
        .page-body {
          margin-right: 90px;
        }
      }
    }

    &.material-icon {
      .page-header {
        padding-left: unset;
        padding-right: 90px;
        margin: 0 auto;

        &.close_icon {
          padding-right: 0;
        }
      }

      .page-body-wrapper {
        .page-body {
          padding-left: 15px;
          padding-right: 90px;
          margin: 0 auto;
        }

        div.sidebar-wrapper {
          &.close_icon {
            ~.page-body {
              padding-right: 15px;
              margin: 0 auto !important;
            }
          }
        }
      }
    }
  }

  .apexcharts-canvas {
    direction: ltr;
  }

  // bookmark app css

  .email-wrap {
    .btn-mail {
      text-align: right;
    }

    .email-app-sidebar {
      ul {
        li {
          a {
            padding-left: unset;
            padding-right: 20px;
          }
        }
      }
    }
  }

  .bookmark-tabcontent {
    .details-bookmark {
      .bookmark-card {
        &.card {
          .favourite-icon {
            text-align: center;
          }

          .title-bookmark {
            text-align: right;
          }
        }
      }
    }
  }

  .bookmark-wrap {
    .main-bookmark {
      .filter-option {
        float: right;
        text-align: right;
      }

      .dropdown-menu {
        .dropdown-item {
          text-align: right;
        }
      }
    }

    .details-bookmark {
      .row {
        >div {
          +div {
            padding-left: 15px !important;
          }
        }
      }
    }
  }

  // index page css start
  .appointment-table {
    table {
      tr {
        td {
          .status-circle {
            left: unset;
            right: 35px;
          }
        }
      }
    }
  }

  .notification {
    .card {
      .d-flex {
        .w-100 {
          p {
            .badge {
              margin-left: unset;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  // Common Table
  .datatable-init,
  .datatable-advance-wrapper,
  .datatable-api-wrapper,
  .data-source-wrapper,
  .autofill-data-tables,
  .support-ticket-table {
    div.dt-container {
      &.dt-bootstrap5 {
        .row {
          >div[class^='col-'] {
            &:first-child {
              margin-left: auto;
              margin-right: unset !important;

              .form-select {
                margin-right: unset;
                margin-left: 0.5em;
              }
            }
          }
        }

        .dt-column-order {
          right: unset;
          left: 12px;
        }
      }
    }
  }

  // index page css end
  @each $progress-gradient-name,
  $progress-gradient-color in (primary, var(--theme-default)),
  (secondary, $secondary-color),
  (success, $success-color),
  (danger, $danger-color),
  (info, $info-color),
  (light, $light-color),
  (dark, $dark-color),
  (warning, $warning-color) {
    .progress-gradient-#{$progress-gradient-name} {
      background-image: linear-gradient(to right, $progress-gradient-color, $transparent-color);

      &::after {
        right: unset;
        left: -1px;
      }

      .animate-circle {
        right: unset;
        left: -8px;
      }
    }
  }

  .progress-animate {
    span {
      left: -10px;
      right: unset;
    }
  }

  // order history
  .common-order-history {
    .form-select {
      padding: .375rem .75rem .375rem 2.25rem;
    }

    .order-history-wrapper {
      .recent-table {
        div.dt-container {
          .dt-layout-row {
            .dt-layout-cell {
              .dt-search {
                right: unset;
                left: -50px;

                @media (max-width:1399px) {
                  left: unset;
                  right: 20px;
                }
              }
            }

            .dt-length {
              right: 20px;
              left: unset;
            }
          }
        }

        table {

          thead,
          tbody {
            tr {

              th,
              td {
                &:first-child {
                  padding-right: 20px;
                  padding-left: 8px;
                }

                &:last-child {
                  padding-left: 20px;
                  padding-right: 8px;
                }
              }
            }
          }

          thead {
            tr {
              th {
                &:first-child {
                  text-align: center !important;
                }
              }
            }
          }
        }
      }
    }
  }


  // ecommerce dashboard css end
  .me-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }

  .pe-4 {
    padding-left: 1.5rem !important;
    padding-right: unset !important;
  }

  .me-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }

  .navs-dropdown {
    .onhover-show-div {
      right: 0;
    }
  }

  .default-according {
    &.style-1 {
      button {
        text-align: right;

        &:before {
          left: 20px;
          right: unset;

          @media (max-width: 575px) {
            left: 15px;
          }
        }

        i {
          right: 18px;
          left: unset;
        }
      }
    }

    .card {
      .card-header {
        i {
          right: 18px;
          left: unset;
        }
      }
    }
  }

  .progress-number {
    direction: ltr;
  }

  .faq-wrap {
    .faq-accordion {
      .card {
        .card-header {
          .btn-link {
            padding-right: 20px !important;

            @media (max-width: 575.98px) {
              padding-right: 10px !important;
            }
          }
        }
      }
    }
  }

  .ProfileCard-details {
    padding-right: 30px;
    padding-left: unset;
    float: right;
  }

  .starter-main {
    .card-body {
      ul {
        padding-right: 30px;
        padding-left: unset;
      }
    }
  }

  .theme-tab {
    .tab-title {
      li {
        a {
          svg {
            margin-left: 5px;
            margin-right: unset;
          }
        }
      }
    }
  }

  .spent {
    .spent-graph {
      .project-budget {
        margin-left: 30px;
        margin-right: unset;
      }
    }
  }

  .support-table {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              padding-right: 0;
              padding-left: unset;
            }
          }
        }
      }
    }
  }

  .order-box {

    .sub-total,
    .total,
    .qty {

      .shipping-class,
      li {

        .shopping-checkout-option,
        span {
          float: left;
        }
      }

      li {
        .count {
          float: left;
        }
      }
    }
  }

  .img-paypal {
    margin-right: 15px;
    margin-left: unset;
  }

  .cart {
    .qty-box {
      .input-group {
        .btn {
          border-radius: 0 !important;
        }
      }
    }
  }

  .product-qnty {
    fieldset {
      .input-group {
        .input-group-prepend {
          .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }

  .grid-options {
    margin-right: 10px;
    margin-left: unset;

    ul {
      li {
        a {
          .line-grid-1 {
            right: 12px;
            left: unset;
          }

          .line-grid-2 {
            right: 18px;
            left: unset;
          }

          .line-grid-3 {
            right: 36px;
            left: unset;
          }

          .line-grid-4 {
            right: 42px;
            left: unset;
          }

          .line-grid-5 {
            right: 48px;
            left: unset;
          }

          .line-grid-6 {
            right: 66px;
            left: unset;
          }

          .line-grid-7 {
            right: 72px;
            left: unset;
          }

          .line-grid-8 {
            right: 78px;
            left: unset;
          }

          .line-grid-9 {
            right: 84px;
            left: unset;
          }

          .line-grid-10 {
            right: 103px;
            left: unset;
          }

          .line-grid-11 {
            right: 109px;
            left: unset;
          }

          .line-grid-12 {
            right: 115px;
            left: unset;
          }

          .line-grid-13 {
            right: 121px;
            left: unset;
          }

          .line-grid-14 {
            right: 127px;
            left: unset;
          }

          .line-grid-15 {
            right: 133px;
            left: unset;
          }
        }
      }
    }
  }

  .product-sidebar {
    .filter-section {
      .card {
        .card-header {
          h6 {
            .pull-right {
              i {
                left: auto;
                right: unset;
              }
            }
          }
        }
      }
    }
  }

  .collection-filter-block {
    svg {
      margin-right: unset;
      margin-left: 14px;
      transform: scaleX(-1);
    }
  }

  .feature-products {
    form {
      .form-group {
        i {
          left: 30px;
          right: unset;
        }
      }
    }
  }

  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-details {
          text-align: right;
        }
      }
    }
  }

  .page-wrapper {
    .page-body-wrapper {
      .page-title {
        .breadcrumb {
          margin-left: unset;
        }
      }
    }
  }

  .bookmark {
    ul {
      li {
        +li {
          margin-left: unset;
          margin-right: 10px;
        }

        .search-form {
          .form-control-search {
            left: -50px;
            right: unset;

            &:before {
              right: 50px;
              left: unset;
            }

            &:after {
              right: 20px;
              left: unset;
            }
          }
        }
      }
    }
  }

  .me-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }

  .footer {
    .pull-right {
      float: none;
    }
  }

  .checkbox_animated,
  .radio_animated {
    margin: 0 0 0 1rem;
  }

  .button.remove {
    left: -10px;
    right: unset;
  }

  .code-box-copy__btn {
    left: 11px;
    right: unset;
  }

  ul {
    &.nav-menus {
      border-bottom: none;
    }

    &.notification-dropdown {
      &.onhover-show-div {
        li {
          text-align: right;
        }
      }
    }
  }

  .badge {
    +.badge {
      margin-right: 5px;
      margin-left: unset;
    }
  }

  .offset-xl-3 {
    margin-right: 25%;
    margin-left: unset;
  }

  .m-l-10 {
    margin-right: 10px !important;
    margin-left: unset !important;
  }

  .m-r-20 {
    margin-left: 20px;
    margin-right: unset;
  }

  .m-r-30 {
    margin-left: 30px;
    margin-right: unset;
  }

  .m-r-5 {
    margin-left: 5px;
    margin-right: unset;
  }

  .text-start {
    text-align: right !important;
  }

  .m-r-10 {
    margin-left: 10px;
    margin-right: unset;
  }

  .pe-3 {
    padding-left: 1rem !important;
    padding-right: unset !important;
  }

  .p-r-0 {
    padding-left: 0;
    padding-right: 15px;
  }

  .m-l-20 {
    margin-right: 20px !important;
    margin-left: unset !important;
  }

  .ps-3 {
    padding-right: 1rem !important;
  }

  .b-r-light {
    border-left: 1px solid $light-color !important;
    border-right: unset !important;
  }

  .float-end {
    float: left !important;
  }

  .float-start {
    float: right !important;
  }

  .text-end {
    text-align: left !important;
  }

  .border-right {
    border-left: 1px solid #dee2e6 !important;
    border-right: unset !important;
  }

  .pe-0 {
    padding-left: 0 !important;
    padding-right: 12px !important;
  }

  .ps-0 {
    padding-right: 0 !important;
    padding-left: unset !important;
  }

  .ms-1 {
    margin-left: unset !important;
    margin-right: 0.25rem !important;
  }

  .owl-carousel {
    direction: ltr;
  }

  .swiper {
    direction: ltr;

    .swiper-wrapper {
      .swiper-slide {
        >* {
          direction: rtl;
        }
      }
    }
  }

  .btn-group {
    > {

      .btn:not(:last-child):not(.dropdown-toggle),
      :not(:last-child)>.btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      .btn:not(:first-child) {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .btn:nth-child(2) {
        border-radius: 0 !important;
      }
    }

    .btn-group> :not(:first-child)>.btn {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .btn+ {

      .btn,
      .btn-group {
        margin-right: -1px;
        border-right: none;
      }
    }

    .btn-group+ {

      .btn,
      .btn-group {
        margin-right: -1px;
      }
    }

    .btn-group-vertical {
      .btn+ {

        .btn,
        .btn-group {
          margin-right: -1px;
        }
      }

      .btn-group+ {

        .btn,
        .btn-group {
          margin-right: -1px;
        }
      }
    }
  }

  .me-3 {
    margin-right: unset !important;
    margin-left: 1rem !important;
  }

  .me-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }

  .ms-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }

  .m-r-15 {
    margin-left: 15px;
    margin-right: unset;
  }

  .b-l-light {
    border-right: 1px solid $light-color !important;
    border-left: unset !important;
  }

  .p-l-0 {
    padding-left: unset;
    padding-right: 0px;
  }

  .ps-4 {
    padding-right: 1.5rem !important;
    padding-left: unset !important;
  }

  .me-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }

  .ms-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }

  .pe-2 {
    padding-right: unset !important;
    padding-left: 0.5rem !important;
  }

  .alert {
    text-align: right;
  }

  .pull-right {
    float: left;
  }

  .form-inline {
    .form-group {
      margin-left: 15px;
      margin-right: 0;
    }
  }

  .card {
    text-align: right;

    &.social-profile {
      text-align: center;
    }

    .card-header {
      h5:not(.mb-0) {
        float: right;
      }

      span {
        clear: both;
      }

      .card-header-right {
        left: 20px;
        right: unset;

        .card-option {
          text-align: left;

          &.list-unstyled {
            padding-right: 0;
            padding-left: unset;
          }
        }
      }

      .pull-left {
        float: right;
      }
    }

    &.full-card {
      left: 0;
      right: unset;
    }

    .card-body {
      .alert {
        svg {
          margin-left: 4px;
          margin-right: unset;
        }
      }

      .square {
        margin-left: 5px;
        margin-right: unset;
      }
    }
  }

  .explore-card {
    text-align: left;

    .group-btn {
      justify-content: flex-end;
    }
  }

  .balance-box {
    text-align: center;
  }

  .purchase-card {
    text-align: center;
  }

  .modal-header {
    .btn-close {
      margin: -0.5rem auto -0.5rem -0.5rem;
    }
  }

  .modal-footer {
    > :not(:first-child) {
      margin-right: .50rem;
      margin-left: 0;
    }
  }

  .form-inline {
    .form-group {
      .col-form-label {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }

  .breadcrumb-item {
    &+.breadcrumb-item {
      padding-right: 0.5rem;
      padding-left: unset;

      &::before {
        padding-left: 0.5rem;
        padding-right: unset;
        float: right;
      }
    }
  }

  code[class*=language-],
  pre[class*=language-] {
    text-align: right;
  }

  .input-group-prepend {
    margin-left: -1px;
    margin-right: unset;
  }

  .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: unset;
    margin-right: -1px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .main-input-group {
    .input-radius {
      .pill-input-group {
        span {
          &:first-child {
            border-radius: 0.375rem !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }

          &:last-child {
            border-radius: 0.375rem !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }
        }
      }
    }

    .input-group-wrapper {
      &.btn-addon {
        .input-group {
          &:last-child {
            .btn {
              &:not(.dropdown-toggle) {
                &:last-child {
                  border-top-left-radius: 0.375rem !important;
                  border-bottom-left-radius: 0.375rem !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .input-group-wrapper {

    &.main-segment-btn,
    &.btn-addon {
      .input-group {
        &:last-child {
          .btn {
            &:not(.dropdown-toggle) {
              border-radius: 0 !important;
            }
          }
        }
      }
    }
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  .was-validated .form-control:valid,
  .form-control.is-valid {
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 12px;
  }

  .input-group>.form-control:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group>.input-group-prepend>.btn,
  .input-group>.input-group-prepend>.input-group-text,
  .input-group>.input-group-append:not(:last-child)>.btn,
  .input-group>.input-group-append:not(:last-child)>.input-group-text,
  .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
  .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .input-group>.input-group-append>.btn,
  .input-group>.input-group-append>.input-group-text,
  .input-group>.input-group-prepend:not(:first-child)>.btn,
  .input-group>.input-group-prepend:not(:first-child)>.input-group-text,
  .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
  .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .border-tab.nav-left .nav-link {
    text-align: left;
  }

  // social app page css
  .socialprofile {
    .social-group {
      ul {
        li {
          &:nth-child(4) {
            margin-right: -22px;
          }
        }
      }
    }
  }

  .social-status {
    form {
      .form-group {
        .form-control-social {
          padding-right: 15px;
          padding-left: unset;
        }
      }
    }

    .d-flex {
      .social-status {
        right: 35px;
        left: unset;
      }
    }
  }

  .social-chat {
    .other-msg {
      margin-right: 40px;
      margin-left: unset;
    }

    .flex-grow-1 {
      span {
        span {
          i {
            transform: scaleX(-1);
          }
        }
      }

      &:before {
        left: 100%;
        right: unset;
        border-left: 7px solid $badge-light-color;
        border-right: unset;
      }

      &:after {
        left: 100%;
        right: unset;
        border-left: 7px solid $white;
        border-right: unset;
      }
    }
  }

  .rounded-timeline {
    .timeline {
      &::after {
        content: '';
        right: 50%;
        left: unset;
        margin-right: -1px;
        margin-left: unset;

        @media (max-width:1199px) {
          right: 75px;
          left: unset;
        }
      }
    }

    .container {
      &::after {
        content: '';
        left: -8px;
        right: unset;
      }

      &::before {
        content: '';
        left: 8px;
        right: unset;
      }

      .content {
        padding: 20px 20px 20px 80px;
        border-radius: 200px 0 0 200px;
      }
    }

    .container.left {
      right: 0;
      left: unset;

      .date {
        left: -75px;
        right: unset;
      }

      .icon {
        left: 56px;
        right: unset;
      }

      @media (max-width:1199px) and (min-width:768px) {
        right: 75px;
        left: unset;

        &::before {
          right: 8px;
          left: unset;
        }

        &::after {
          right: -8px;
          left: unset;
        }

        .date {
          right: -75px;
          left: unset;
        }

        .icon {
          right: 56px;
          left: unset;
        }

        .content {
          padding: 20px 80px 20px 20px;
          border-radius: 0 200px 200px 0;
        }
      }

      @media (max-width:767px) {
        right: 0;
        left: unset;
      }
    }

    .container.right {
      right: 50%;
      left: unset;

      &::after {
        right: -8px;
        left: unset;
      }

      &::before {
        right: 8px;
        left: unset;
      }

      .date {
        right: -75px;
        left: unset;
      }

      .icon {
        right: 56px;
        left: unset;
      }

      .content {
        padding: 20px 80px 20px 20px;
        border-radius: 0 200px 200px 0;
      }

      @media (max-width:1199px) {
        right: 75px;
        left: unset;

        &::after {
          right: -8px;
          left: unset;
        }
      }
    }

    @media (max-width: 767px) {
      .timeline {
        &::after {
          right: 90px;
          left: unset;
        }
      }

      .container {
        padding-right: 120px;
        padding-left: 30px;
      }

      .container.right {
        right: 0%;
        left: unset;

        &::after {
          right: 82px;
          left: unset;
        }

        &::before {
          right: 95px;
          left: unset;
        }

        .date {
          left: auto;
          right: 15px;
        }

        .icon {
          left: auto;
          right: 146px;
        }

        .content {
          padding: 30px 90px 30px 30px;
          border-radius: 0 200px 200px 0;
        }
      }

      .container.left {
        &::after {
          right: 82px;
          left: unset;
        }

        &::before {
          right: 95px;
          left: unset;
        }

        .date {
          left: auto;
          right: 15px;
        }

        .icon {
          left: auto;
          right: 146px;
        }

        .content {
          padding: 30px 90px 30px 30px;
          border-radius: 0 200px 200px 0;

        }
      }
    }

    @media (max-width:610px) {
      .timeline {
        &::after {
          right: 8px;
          left: unset;
        }

        .container {
          padding-right: 30px;
          padding-left: unset;

          &::before,
          &::after {
            right: 0;
            left: unset;
          }

          .icon {
            right: 50px;
            left: unset;
          }

          .content {
            @media (max-width:475px) {
              padding: 30px 75px 30px 30px;
            }
          }
        }
      }
    }

    @media (max-width:440px) {
      .timeline {
        padding-top: 20px;

        .container {
          padding-right: 0;
          padding-left: unset;

          .icon {
            right: 45%;
            left: unset;
          }
        }

        &::after {
          right: 50%;
          left: unset;
        }
      }
    }

    @media (max-width:390px) {
      .timeline {
        .container {
          .icon {
            right: 44%;
            left: unset;
          }
        }
      }
    }

    @media (max-width:335px) {
      .timeline {
        .container {
          .icon {
            right: 42%;
            left: unset;
          }
        }
      }
    }
  }

  // Animated timeline 
  .animated-timeline {
    .timeline-block {
      &::before {
        right: 50%;
        left: unset;
      }

      .timeline-event {
        &:before {
          right: -27px;
          left: unset;
        }

        &:nth-child(odd) {
          margin-right: calc(50% + 20px);
          margin-left: unset;
        }

        &:nth-child(even) {
          margin-right: calc(50% - 321px);
          margin-left: unset;

          &:before {
            right: calc(100% + 16px);
            left: unset;
          }
        }
      }
    }

    @media (max-width:767px) {
      .timeline-block {
        &::before {
          right: 18px;
          left: unset;
        }

        .title {
          text-align: right;
        }

        .timeline-event {
          &::before {
            right: -49px;
            left: unset;
          }

          &:nth-child(odd) {
            margin-right: calc(50% - 260px);
            margin-left: unset;
          }

          &:nth-child(even) {
            margin-right: calc(50% - 260px);
            margin-left: unset;

            &:before {
              left: unset;
              right: calc(100% + 33px);
            }
          }
        }
      }
    }

    @media (max-width:767px) {
      .timeline-block {
        .timeline-event {
          &:nth-child(even) {
            &::before {
              right: unset;
              left: calc(100% + 31px);
            }
          }

          &::before {
            right: -47px;
            left: unset;
          }
        }
      }
    }

    @media (max-width:767px) {
      .timeline-block {
        .timeline-event {
          &:nth-child(odd) {
            margin-right: 58px;
            margin-left: unset;
          }

          &:nth-child(even) {
            margin-right: 58px;
            margin-left: unset;
          }
        }
      }
    }

    @media (max-width:440px) {
      .timeline-block {
        .timeline-event {
          &::before {
            right: -45px;
            left: unset;
          }

          &:nth-child(even) {
            &::before {
              left: calc(100% + 33px);
              right: unset;
            }
          }
        }
      }
    }
  }

  .photos {
    ul {
      li {
        margin-right: unset;
        margin-left: 10px;
      }
    }
  }

  .avatar-showcase {
    .friend-pic {
      margin-left: 8px;
      margin-right: unset;
    }

    .pepole-knows {
      ul {
        li {
          margin-left: 21px;
          margin-right: unset;

          &:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          left: 100px;
          right: unset;

          li {
            margin-left: 10px;
            margin-right: unset;

            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .lang {
    span {
      span {
        margin-left: unset;
        margin-right: 5px;
      }
    }
  }

  // faq page css
  .faq-form {
    .search-icon {
      left: 12px;
      right: unset;
    }
  }

  .faq-widgets {
    .flex-grow-1 {
      p {
        padding-left: 20px;
        padding-right: unset;
      }
    }
  }

  .header-faq,
  .faq-title {
    text-align: right;
  }

  .faq-accordion.default-according {
    .card {
      .faq-header {
        svg {
          left: 30px;
          right: unset;
        }
      }

      .btn-link {
        svg {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
  }

  .navigation-option {
    ul {
      li {
        padding: 8px 0 8px 8px;

        a {
          padding-right: 40px;
          padding-left: unset;

          svg {
            right: 15px;
            left: unset;
          }
        }
      }
    }
  }

  .updates-faq {
    margin-right: unset;
    margin-left: 20px;
  }

  // knowledgebase page css
  .browse {
    .browse-articles {
      span {
        svg {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
  }

  // learning app page css
  .categories {
    .learning-header {
      padding-right: 15px;
      padding-left: unset;
    }
  }

  // internationalization page css
  .main {
    .langChoice {
      left: 30px;
      right: unset;
    }
  }

  .pricing-block {
    svg {
      direction: ltr;
    }
  }

  // chart peity css
  p {
    &.data-attributes {
      svg {
        margin-left: 10px;
        margin-right: unset;
      }
    }
  }

  // avatars page css
  .avatars {
    .avatar {
      margin-left: 10px;
      margin-right: unset;

      &:last-child {
        margin-left: 0;
      }

      .status {
        left: 4px;
        right: unset;
      }
    }
  }

  .customers {
    &.avatar-group {
      margin-left: 30px;
      margin-right: unset;
    }
  }

  .card {
    .card-header {
      h5 {
        float: none !important;
      }
    }
  }

  // knob chart css
  .knob-chart {
    .chart-clock-main {
      .clock-small {
        right: 10px;
        left: unset;
      }
    }
  }

  // to-do css
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        .btn-label {
          margin-left: 5px;
          margin-right: unset;
        }

        left: 30px;
        right: unset;
      }

      #todo-list {
        li {
          .task-container {
            .task-action-btn {
              text-align: left;
            }
          }
        }
      }
    }

    .notification-popup {
      left: 10px;
      right: unset;
    }
  }

  .todo-options {
    .badges-todo {
      h6 {
        margin-left: 10px;
        margin-right: unset;

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

  .todo-list-btns {
    .dropdown-basic {
      .btn-group {
        .form-group {
          .checkbox {
            padding-right: 14px;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            padding-left: unset;
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
          }
        }
      }

      .separated-btn {
        margin-right: -6px;
        margin-left: unset;

        .btn {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
      }
    }
  }

  .search-todo {
    .dropdown-basic {
      .btn-group {
        margin-left: 18px;
        margin-right: unset;
      }
    }
  }

  // Dropdowns
  .dark-dropdown-box {
    .btn-group {
      .dropdown-toggle-split {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }
    }
  }

  //sticky note css
  .sticky-note {
    .note {
      float: right;
    }
  }

  #testimonial {
    .owl-stage-outer {
      .owl-stage {
        .owl-item {
          .slide--item {
            .d-flex {
              .me-3 {
                margin-right: 1rem !important;
                margin-left: unset !important;
              }
            }
          }
        }
      }
    }
  }

  .needs-validation,
  .form-row {

    .input-group>.form-control:not(:last-child),
    .input-group>.custom-select:not(:last-child) {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .form-check {
    padding-right: 1.25rem;
    padding-left: unset;

    .form-check-input {
      float: right;
      margin-left: unset;
      margin-right: -1.5em;
    }
  }

  .form-check-reverse {
    .form-check-input {
      float: right;
      margin-left: 0;
      margin-right: 10px;
    }
  }

  // email css
  .email-wrap {
    .row {
      .col-xl-6 {
        padding-right: 0;
        padding-left: 15px;
      }

      .col-xl-3 {
        +.col-xl-3 {
          padding-left: 0;
          padding-right: 15px;
        }
      }
    }

    .email-app-sidebar {
      .main-menu {
        &>li {
          text-align: right;

          a {

            i {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }
      }

      ul {
        padding-right: 0;
      }
    }

    .email-right-aside {
      .email-body {
        .pe-0 {
          padding-right: unset !important;
        }
      }

      .radius-left {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-radius: 4px;
      }
    }
  }

  // calender css
  #renderRange {
    float: left;
    padding-left: unset;
    padding-right: 12px;
  }

  .event-calendar {
    direction: ltr;
  }

  .calendar-wrap {
    .fc {
      direction: rtl;
      text-align: right;

      .fc-toolbar {
        .fc-left {
          float: right;
        }

        .fc-right {
          float: left;
        }

        &>* {
          &>* {
            margin-left: 0;
          }
        }
      }
    }

    .fc-events-container {
      text-align: right;
    }

    .fc-scroller {
      margin-right: unset;
      margin-left: -17px;
    }
  }

  .fc-agenda-view {
    .fc-day-grid {
      .fc-row {
        margin-right: 0 !important;
      }
    }
  }

  .fc-unthemed {
    .fc-row {
      margin-right: 0 !important;
    }
  }

  // buttons css
  .btn-showcase {
    .btn {
      margin-right: unset;
      margin-left: 10px;
    }
  }

  .btn-square {
    &.dropdown-toggle {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  // button-builder css
  &.button-builder {
    ul.the-icons {
      li {
        float: right;
      }
    }

    .color-slelector {
      float: right;
    }

    .custom-button-color {
      .form-control {
        border-radius: 0 5px 5px 0;

        &.pull-left {
          float: right;
        }
      }
    }

    .hint-label {
      float: right;
      padding: 4px 020px 0 0;
    }
  }

  #customer-review {
    .owl-item {
      img {
        order: 2;
      }
    }
  }

  .options {
    >div {
      margin: 0 0 8px 8px;
    }
  }

  // modal page
  .modal-dialog {
    .modal-content {
      .modal-body {
        p {
          a {
            margin-left: 0;
          }
        }
      }
    }
  }

  // forms page
  .drag-box {
    fieldset {
      .component {
        .form-group {
          .text-lg-left {
            text-align: right !important;
          }

          .input-group {
            .input-group-prepend {

              .btn,
              .input-group-text {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
              }

              .btn-right,
              .checkbox-radius {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }

            #prependedcheckbox {
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            }

            #appendedcheckbox {
              border-right-color: $light-semi-gray;
            }

            #buttondropdown {
              margin-right: unset;
              margin-left: -1px;
            }
          }
        }
      }
    }
  }

  .drag {
    form {
      text-align: right;

      input {
        text-align: right;
      }

      label {
        text-align: right !important;
      }

      .form-group {
        .input-group {
          .input-group-prepend {

            .btn,
            .input-group-text {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-top-right-radius: 0.25rem;
              border-bottom-right-radius: 0.25rem;
            }

            .btn-right,
            .checkbox-radius {
              border-top-left-radius: 0.25rem;
              border-bottom-left-radius: 0.25rem;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }

          #buttondropdown {
            margin-right: unset;
            margin-left: -1px;
          }
        }
      }
    }
  }

  .draggable {
    label {
      text-align: right;
    }
  }

  .form-builder-column {
    .tab-content {
      .tab-pane {
        .theme-form {
          .ui-draggable {
            .input-group {
              >.input-group-prepend {
                >.input-group-text {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                  border-top-right-radius: 0.25rem;
                  border-bottom-right-radius: 0.25rem;
                }
              }
            }
          }
        }
      }
    }

    #pills-tabContent {
      .theme-form {

        .form-group,
        .ui-draggable {
          .input-group {
            #buttondropdown {
              margin-right: unset;
              margin-left: 15px;
            }

            .input-group-btn {
              button {
                border-radius: 4px !important;
              }
            }
          }
        }
      }
    }
  }

  .form-horizontal {
    &.theme-form {
      fieldset {
        .tab-content {
          .tab-pane {
            .component {
              .form-group {
                .text-lg-left {
                  text-align: right !important;
                }

                .input-group {
                  .input-group-prepend {

                    .btn,
                    .input-group-text {
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                      border-top-right-radius: 0.25rem;
                      border-bottom-right-radius: 0.25rem;
                    }

                    .btn-right,
                    .checkbox-radius {
                      border-top-left-radius: 0.25rem;
                      border-bottom-left-radius: 0.25rem;
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }

  .form-builder {
    .component {
      .form-group {
        .input-group {
          #buttondropdown {
            margin-right: unset;
            margin-left: -1px;
          }
        }
      }
    }
  }

  // compact small page
  .page-wrapper {
    .page-body-wrapper {
      .logo-wrapper {
        box-shadow: 9px 0 20px rgba($semi-dark , 0.1) !important;

        a {
          transform: translate(-64px);
        }
      }
    }
  }

  // search page
  .search-page {
    .nav i {
      display: inline-block;
      margin-left: 10px;
      margin-right: unset;
    }
  }

  .product-stts {
    display: inline-block;

    .ms-1 {
      margin-right: 0.25rem !important;
      margin-left: unset !important;
    }
  }

  .search-form input {
    padding: 10px 70px 10px 10px;
    text-align: right;
  }

  // icons page
  .icon-hover-bottom {
    .icon-title {
      text-align: right;
    }
  }

  .icon-lists {
    div {
      i {
        margin: 0 10px 0 15px;
      }
    }

    ul {
      padding-right: 0;
    }
  }

  ul {
    &.icon-lists {
      padding-right: 30px;
    }
  }

  // customizer
  .floated-customizer-btn {
    &.third-floated-btn {
      left: 35px;
      right: unset;
    }

    &>span {
      margin-right: 10px;
    }
  }

  .floated-customizer-panel {
    text-align: right;
    left: -85px;
    right: unset;

    &.active {
      left: 35px;
      right: unset;
    }

    .close-customizer-btn {
      left: 30px;
      right: unset;
    }

    ul {
      padding-right: 0;
    }
  }

  // form builder-2 page
  .form-builder-2-header {
    >div {
      nav {
        float: left;
      }
    }
  }

  .radio {
    label {
      padding-left: 0;
      padding-right: 5px;

      &::before {
        right: 0;
        margin-right: -20px;
        left: unset;
      }
    }
  }

  // sidebar main page
  .page-wrapper {
    .page-body-wrapper {
      .page-title {
        .row {
          h3 {
            text-align: right;
            padding-left: 20px;
            padding-right: unset !important;
          }

          h6 {
            text-align: right;
          }
        }
      }
    }

    &.compact-wrapper {
      .page-header {
        margin-right: 265px;
        margin-left: unset;
        width: calc(100% - 265px);

        &.close_icon {
          margin-right: 80px;
          margin-left: unset;
          width: calc(100% - 80px);
        }
      }
    }

    &.modern-type {
      .page-header {
        margin-right: 0;
        width: calc(100% - 0px);

        &.close_icon {
          margin-right: 0;
          width: calc(100% - 0px);
        }

        .header-wrapper {
          .logo-wrapper {
            margin-right: unset;
            margin-left: 30px;
          }

          .toggle-sidebar {
            margin-right: unset;
            margin-left: 15px;
          }
        }
      }

      .page-body-wrapper {
        .page-body {
          margin-right: 310px;
        }

        .footer {
          margin-left: 55px;
          margin-right: 360px;
        }

        div.sidebar-wrapper {
          &.close_icon {
            ~.page-body {
              margin-right: 0px;
            }

            ~footer {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  // page main header
  .page-header {
    .header-wrapper {
      .nav-right {
        text-align: right;

        .onhover-show-div {
          right: unset;
          left: 0;

          @media (max-width:575.98px) {
            left: 12px;
          }

          li {
            .d-flex {
              .flex-grow-1 {
                >span {
                  padding-right: unset;
                  padding-left: 10px;
                }
              }
            }
          }
        }

        .cart-dropdown {
          @media (max-width: 575.98px) {
            li {
              padding: 10px;
            }
          }

          .close-circle {
            right: unset;
            left: 0;
          }
        }

        >ul {
          >li {
            .lang-txt {
              margin-left: 0;
              margin-right: 8px;
            }

            &:before {
              right: 0;
              left: unset;
            }

            .dot {
              left: 17px;
              right: unset;
            }

            &.onhover-dropdown {
              &:hover {
                .onhover-show-div {
                  &:before {
                    right: 10px;
                    left: unset;
                  }

                  &:after {
                    right: 10px;
                    left: unset;
                  }
                }
              }

              &:last-child {
                padding-left: 0;
                padding-right: 0 !important;
              }
            }

            &:last-child {
              padding-right: 20px;
              padding-left: 0;
            }

            text-align: right;

            i {
              &.ms-2 {
                margin-right: 0.5rem !important;
                margin-left: unset !important;
              }
            }
          }
        }

        .profile-dropdown {
          li {
            svg {
              margin-right: unset;
              margin-left: 10px;
            }
          }

        }
      }

      .mobile-sidebar {
        padding-left: 20px;
        padding-right: unset;
      }
    }

    &.open {
      margin-right: 0;
    }
  }

  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        right: initial;
        left: -30px;
        padding: 0;

        &:before {
          right: inherit !important;
          left: 35px !important;
        }

        &:after {
          right: inherit !important;
          left: 35px !important;
        }

        li {
          margin-right: 0 !important;

          span {
            &.badge {
              margin-left: unset !important;
            }

            svg {
              margin-right: unset;
              margin-left: 10px;
            }
          }

          .notification-icon {
            margin-left: 20px;
            margin-right: unset;
          }
        }
      }
    }
  }

  // alert page
  .card-body {
    .alert {
      svg {
        ~p {
          padding-left: unset;
          padding-right: 20px;
        }
      }
    }
  }

  .alert {
    i {
      margin-left: 5px;
      margin-right: 0;
    }

    &.alert-dismissible {
      padding-right: 15px;
      padding-left: 2.5rem;

      .me-2 {
        margin-right: auto !important;
      }
    }
  }

  .inverse {
    padding: 13px 65px 13px 20px;

    i {
      left: unset;
      right: 0;
    }
  }

  .card-body {
    &.alerts-icon {
      .alert {
        >div {
          margin-right: unset;
          margin-left: 10px;
        }

        svg {
          margin: 0;
        }
      }
    }
  }

  @each $var in $alert-name {
    $i: index($alert-name, $var);

    .alert-#{$var}.inverse {
      &:before {
        border-right: 7px solid nth($alert-color, $i);
        right: 54px;
        left: unset;
        border-left: unset;
      }
    }
  }

  .alert-dismissible {
    .btn-close {
      left: 0;
      right: unset;

      span {
        padding-left: 0;
        padding-right: unset;
      }
    }
  }

  .icofont {
    &.icofont-truck {
      display: inline-block;
    }
  }

  // blog page
  .blog-box {
    .blog-details {
      padding-left: 20px;
      text-align: right;

      .blog-social {
        padding-right: 0;

        li {
          padding-left: 40px;
          padding-right: unset;

          &:first-child {
            border-left: 1px solid #777777;
            border-right: unset;
            padding-left: 40px;
            padding-right: unset;

            @media screen and (min-width: 1200px) and (max-width: 1366px) {
              padding-left: 8px;
              padding-right: unset;
            }
          }

          &:last-child {
            @media (max-width:360px) {
              padding-left: unset;
              padding-right: 0;
            }
          }

          &+li {
            margin-right: 20px;
            margin-left: unset;
            padding-left: 0;

            @media screen and (min-width: 1200px) and (max-width: 1366px) {
              padding-right: 8px;
              padding-left: unset;
            }
          }
        }
      }
    }

    .blog-details-main {
      .blog-social {
        li {
          border-left: 1px dotted;
          border-right: unset;

          &:last-child {
            border-left: none;
          }
        }
      }
    }

    .blog-date {
      span {
        font-size: 36px;
        font-weight: 500;
        padding-left: 5px;
      }
    }
  }

  .comment-box {
    .d-flex {
      img {
        margin-left: 45px;
        margin-right: unset;
      }

      h6 {
        text-align: right;

        span {
          padding-left: unset;
          padding-right: 10px;
        }
      }
    }

    ul {
      ul {
        margin-right: 135px;
        margin-left: unset;
        padding-right: 0;

        @media (max-width: 575.98px) {
          margin-right: 30px;
        }
      }
    }

    .comment-social {
      text-align: left;

      li {
        padding-left: 0;
        padding-right: 20px;

        &:first-child {
          border-left: 1px solid $light-semi-gray;
          border-right: unset;
          padding-right: 0;
          padding-left: 20px;
        }
      }
    }
  }

  .blog-single {
    .comment-box {
      .comment-social {
        li {
          &:first-child {
            @media (max-width:767.98px) {
              padding-left: 15px;
              padding-right: 0;

            }
          }
        }
      }
    }
  }

  // Buttons
  .button-wrapper {
    .btn-group {
      &.select-checkbox {
        .btn {
          &:not(:last-child) {
            border-top-right-radius: 0.25rem !important;
            border-bottom-right-radius: 0.25rem !important;
          }

          &:nth-of-type(2) {
            border-top-right-radius: unset !important;
            border-bottom-right-radius: unset !important;
          }
        }
      }
    }
  }

  // button builder page
  .button-builder-wrap {
    .form-group {
      .btn {
        margin-left: 15px;
        margin-right: 0;
        padding: 9px;
      }
    }
  }

  .button-builder {
    ul {
      &.the-icons {
        padding-right: 0 !important;
      }
    }
  }

  // chat page
  .chat-msg-box {
    ul {
      padding-right: 0;
    }
  }

  .fg-emoji-container {
    left: 135px !important;

    .fg-emoji-picker-search-icon {
      right: unset;
      left: -6px;
    }
  }

  // cke editor page
  .cke_toolbar,
  .cke_toolgroup,
  a.cke_button,
  .cke_combo_text {
    float: right;
  }

  .cke_reset_all,
  .cke_reset_all *,
  .cke_reset_all a,
  .cke_reset_all textarea {
    text-align: right;
  }

  .cke_combo_text {
    padding-left: unset;
    padding-right: 10px;
  }

  #editor1 {
    #cke_editor1 {
      #cke_1_contents {
        iframe {
          html {
            direction: rtl !important;
          }
        }
      }
    }
  }

  // coming soon page
  #clockdiv {
    ul {
      padding-right: 0;
    }
  }

  // social app page
  .custom-card {
    .card-footer {
      &>div {
        &+div {
          border-right: 1px solid $light-semi-gray;
          border-left: unset !important;
        }
      }
    }
  }

  .card-social {
    padding-right: 0;
  }

  .card-absolute {
    .card-header {
      right: 15px;
      left: unset;
    }
  }

  // datatables page
  .dataTables_wrapper {
    .dataTables_filter {
      input[type="search"] {
        margin-right: 10px;
        margin-left: 0;
      }
    }

    .dataTables_length {
      float: right;
    }

    .dataTables_filter {
      margin-left: unset;
      margin-right: 15px;
      float: left;
    }

    .dataTables_info {
      float: right;
    }

    .advance-5_filter {
      margin-left: 0;
    }

    .btn-group {
      button {
        margin-left: 5px;
        margin-right: unset;

        &:last-child {
          margin-left: 0;
        }
      }
    }

    table {
      &.dataTable {
        tbody {

          td,
          th {
            &.select-checkbox {
              &:after {
                margin-left: 31px;
              }
            }
          }
        }
      }
    }
  }

  div {
    &.dataTables_wrapper {
      div {
        &.dataTables_paginate {
          margin-right: 15px !important;
          margin-left: 0 !important;
          float: left;
        }
      }
    }
  }

  table {
    &.dataTable {
      thead {
        &>tr {
          &>th {
            padding-left: 30px;
            padding-right: 12px;

            &:before {
              left: 1em !important;
              right: unset;
            }

            &:after {
              left: 0.5em !important;
              right: unset;
            }

          }
        }
      }
    }
  }

  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    left: 1em !important;
    right: unset;
  }

  .dataTables_scrollHeadInner {
    padding-right: unset !important;
    padding-left: 0 !important;
  }

  div {
    &.table-responsive {
      &>div {
        &.dataTables_wrapper {
          &>div {
            &.row {
              &>div[class^="col-"]:first-child {
                padding-right: 0;
                padding-left: 0;
              }

              &>div[class^="col-"]:last-child {
                padding-right: 0;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .dt-plugin-buttons {
    button {
      &.m-r-10 {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }

  // datetime picker page
  .datetime-picker {
    .theme-form {
      .form-group {
        label {
          text-align: left !important;
        }

        .input-group-text {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .input-group {
          .form-control {
            border-radius: 0.25rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }

  // dropdown page
  .rtl-dropdown {
    .btn-group {
      .dropdown-menu {
        right: 0 !important;
      }
    }
  }

  .heading-dropdown,
  .dropdown-option {
    .dropdown-menu {
      right: 0 !important;
    }
  }

  .split-dropdown {
    .dropdown-basic {
      .separated-btn {
        .dropdown-menu {
          right: 0 !important;
        }
      }
    }
  }

  .dropdown-basic {
    .separated-btn {
      .btn {
        border-radius: 0.25rem;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  // error page
  .error-wrapper {
    .col-md-8 {
      &.offset-md-2 {
        margin: 0 auto !important;
      }
    }
  }

  // product page
  .product-price {
    del {
      padding-left: 20px;
      padding-right: 0;
    }
  }

  .product-box {
    .modal {
      .modal-header {
        .btn-close {
          right: unset;
          left: 15px;
        }
      }
    }

    .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
    .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
      border-left-color: #007bff;
    }

    .ribbon-bookmark.ribbon-vertical-left:before,
    .ribbon-bookmark.ribbon-vertical-right:before {
      right: 0;
      left: unset;
      border-left: 15px solid #2a3142;
    }

    .ribbon-bookmark.ribbon-info:before {
      border-left-color: transparent;
    }

    .ribbon-warning.ribbon-clip:before {
      border-left-color: #ff850d;
      border-right-color: unset;
    }

    .ribbon-clip {
      right: -14px;
      left: unset;

      &:before {
        right: 0;
        left: unset;
      }
    }
  }

  .product-hover {
    ul {
      padding-right: 0;
    }
  }

  .customer-review {
    .d-flex {
      img {
        margin-left: 25px;
        margin-right: 0;
      }
    }
  }

  .nav {
    padding-right: 0;
  }

  .custom-tab {
    padding: 4px;
  }

  .custom-file-label {
    &::after {
      left: 0;
      right: unset;
    }
  }

  // form wizard 4 page
  .wizard-4 {
    ul {
      &.anchor {
        float: right;
      }
    }

    .step-container {
      clear: unset;
    }

    .form-group {
      text-align: right;

    }

    .action-bar {
      .btn {
        float: left;
      }
    }
  }

  .f1 {
    .f1-buttons {
      text-align: left;
    }

    .f1-steps {
      .f1-step {
        float: right;
      }
    }
  }

  // tree view page
  .jstree-default {
    .jstree-node {
      margin-left: unset;
      margin-right: 24px;
      background-position: -292px -4px;
      background-repeat: repeat-y;
    }
  }

  // Default Dashboard
  .sales-report {
    .card-body {
      .balance-data {
        right: unset;
        left: 60px;
      }
    }
  }

  .statistical-card {
    li {
      margin-right: unset;
      margin-left: 15px;

      +li {
        padding-left: 14px;
        padding-right: 15px;
        margin-left: 15px;
        margin-right: 15px;

        &::after {
          left: unset;
          right: 0;
          transform: translate(15px, -50%);
        }
      }
    }
  }

  .appointments-wrapper {
    li {
      >span {
        margin-right: unset;
        margin-left: 12px;
      }

      .main-box {
        margin-left: unset;
        margin-right: 20px;
      }

      &::before {
        left: unset;
        right: 61px;
      }
    }
  }

  .recent-order-table {
    &.currency-table {
      &.recent-table {
        table {

          thead,
          tbody {
            tr {

              th,
              td {

                &:first-child {
                  padding-right: 20px;
                  padding-left: unset;
                }

                &:last-child {
                  padding-left: 20px;
                  padding-right: unset;
                }

                @media (max-width:1660px) {
                  &:nth-child(6) {
                    padding-left: 20px;
                    padding-right: unset;
                  }
                }
              }
            }
          }

          tbody {
            tr {
              td {
                .btn {
                  min-width: 135px;
                }
              }

              &:hover {
                td {
                  div {
                    .currency-icon {
                      img {
                        animation: tada 1.5s ease infinite;
                      }
                    }

                    h6 {
                      color: var(--body-font-color);
                    }

                    a {
                      color: var(--theme-default);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .main-customer-table {
    .table-responsive {
      .table {

        thead,
        tbody {
          tr {

            th,
            td {

              &:first-child {
                padding-right: 20px;
                padding-left: unset;
              }

              &:last-child {
                padding-left: 20px;
                padding-right: unset;
              }
            }
          }
        }
      }
    }
  }

  // Dashboard-2
  .seller-table {

    thead,
    tbody {
      tr {

        th,
        td {
          &:first-child {
            padding-right: 20px;
            padding-left: unset;
          }

          &:last-child {
            padding-left: 20px;
            padding-right: unset;
          }
        }
      }
    }
  }

  .treading-product {
    .table-responsive {
      .table {

        thead,
        tbody {
          tr {

            th,
            td {

              &:first-child {
                padding-right: 20px !important;
                padding-left: unset;
              }

              &:last-child {
                padding-left: 20px !important;
                padding-right: unset;
              }
            }
          }
        }
      }
    }
  }

  .stock-report {
    .table-responsive {
      .table {

        thead,
        tbody {
          tr {

            th,
            td {
              &:first-child {
                padding-right: 20px !important;
                padding-left: unset;
              }

              &:last-child {
                padding-left: 20px !important;
                padding-right: unset;
              }

              @media (max-width:1798px) {
                &:nth-child(4) {
                  padding-left: 20px;
                  padding-right: unset;
                }
              }
            }
          }
        }
      }
    }
  }

  .recent-transaction {

    thead,
    tbody {
      tr {

        th,
        td {
          &:first-child {
            padding-right: 20px !important;
            padding-left: unset;
          }

          &:last-child {
            padding-left: 20px !important;
            padding-right: unset;
          }
        }
      }
    }
  }

  .e-common-button {
    .btn {
      &:first-child {
        margin-left: 12px;
        margin-right: unset;
      }

      svg {
        margin-left: 6px;
        margin-right: unset;
      }
    }
  }

  .balance-widget {
    .mobile-right-img {
      left: -15%;

      @media (max-width:1475px) and (min-width:1400px) {
        left: -18%;
      }

      @media (max-width:1399px) and (min-width:936px) {
        left: -10%;
      }

      @media (max-width:935px) and (min-width:768px) {
        left: -15%;
      }

      @media (max-width:767px) and (min-width:621px) {
        left: -35px;
      }

      @media (max-width:620px) and (min-width:521px) {
        left: -60px;
      }

      @media (max-width:520px) {
        left: -75px;
      }
    }
  }

  // Dashboard-03
  .link-only {
    svg {
      transform: scaleX(-1);
    }
  }

  .course-widget {
    a {
      svg {
        transform: scaleX(-1);
      }
    }
  }

  .t-top-mentor {
    .table-responsive {
      div.dt-container {
        .dt-layout-row {
          .dt-layout-cell {
            .dt-search {
              @media (max-width:1261px) {
                right: 20px;
                left: unset;
              }
            }
          }
        }
      }
    }

    thead,
    tbody {
      tr {

        th,
        td {
          &:first-child {
            padding-right: 20px !important;
            padding-left: unset;
          }

          &:last-child {
            padding-left: 20px !important;
            padding-right: unset;
          }
        }
      }
    }
  }


  .completed-course-table {
    .table-responsive {
      div.dt-container {
        .dt-layout-row {
          .dt-layout-cell {
            .dt-search {
              @media (max-width:1261px) {
                right: 20px;
                left: unset;
              }
            }
          }
        }
      }
    }

    thead,
    tbody {
      tr {

        th,
        td {
          &:first-child {
            padding-right: 20px !important;
            padding-left: unset;
          }

          &:last-child {
            padding-left: 20px !important;
            padding-right: unset;
          }

          &:nth-child(4) {
            @media (max-width:1671px) and (min-width:1351px) {
              padding-left: 20px;
              padding-right: unset;
            }
          }
        }
      }
    }
  }

  // Dashboard-04
  .currency-table {
    &.recent-table {
      table {

        thead,
        tbody {
          tr {

            th,
            td {

              &:first-child {
                padding-right: 20px !important;
                padding-left: 8px;
              }

              &:last-child {
                padding-left: 20px !important;
                padding-right: 8px;
              }
            }
          }
        }
      }

      &.average-sale {
        &.table-responsive {
          div.dt-container {
            .dt-layout-row {
              .dt-layout-cell {
                .dt-search {
                  @media (max-width:1199px) {
                    right: 20px;
                    left: unset;
                  }
                }
              }
            }
          }

          thead,
          tbody {
            tr {

              th,
              td {

                &:first-child {
                  padding-right: 20px !important;
                }

                &:last-child {
                  padding-left: 20px !important;
                }

                &:nth-child(3) {
                  @media (max-width:1316px) and (min-width:768px) {
                    padding-left: 20px;
                  }
                }
              }
            }
          }
        }
      }

      &.performer-table {
        &.table-responsive {
          div.dt-container {
            .dt-layout-row {
              .dt-layout-cell {
                .dt-search {
                  @media (max-width:1199px) {
                    right: 20px;
                    left: unset;
                  }
                }
              }
            }
          }

          thead,
          tbody {
            tr {

              th,
              td {

                &:first-child {
                  padding-right: 20px !important;
                }

                &:last-child {
                  padding-left: 20px !important;
                }

                &:nth-child(5) {
                  @media (max-width:1696px) and (min-width:1200px) {
                    padding-left: 20px;
                    padding-right: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .transaction-table {
    &.recent-table {
      table {

        thead,
        tbody {
          tr {

            th,
            td {
              &:first-child {
                padding-right: 20px !important;
                padding-left: 8px;
              }

              &:last-child {
                padding-left: 20px !important;
                padding-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  // Dashboard-05
  .campaign-table {
    &.recent-table {
      table {
        tr {

          th,
          td {
            &:first-child {
              padding-right: 20px;
              padding-left: 8px;
            }

            &:last-child {
              padding-left: 20px;
              padding-right: 8px;
            }

            @media (max-width:1260px)and (min-width:1200px) {
              &:nth-child(5) {
                padding-left: 20px;
                padding-right: 8px;
              }
            }
          }
        }
      }
    }

    .recent-table {
      &.table-responsive {
        div.dt-container {
          .dt-layout-row {
            &:last-child {
              @media (max-width:1399px) {
                flex-direction: column;
              }
            }
          }
        }

        table {

          thead,
          tbody {
            tr {

              td,
              th {
                &:first-child {
                  min-width: 88px;

                  @media (max-width:775px) {
                    min-width: 116px;
                  }
                }

                &:nth-child(2) {
                  min-width: 80px;
                }

                &:nth-child(3),
                &:last-child {
                  min-width: 36px;
                }

                &:nth-child(3) {
                  @media (max-width:1566px) and (min-width:1200px) {
                    display: none;
                  }
                }

                @media (max-width:1455px)and (min-width:1200px) {
                  &:last-child {
                    display: none;
                  }

                  &:nth-child(6) {
                    padding-right: 20px;
                  }
                }

                @media (max-width:1399px) and (min-width:1200px) {
                  &:nth-child(6) {
                    display: none;
                  }

                  &:nth-child(5) {
                    padding-right: 20px;

                  }
                }

                @media (max-width:1255px) and (min-width:1200px) {
                  min-width: auto;
                }

                @media (max-width:1215px) and (min-width:1200px) {
                  &:nth-child(4) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .mobile-app-card {
    .card-body {
      .wavy {
        margin-left: -185px;
        margin-right: unset;

        @media (max-width:1693px) and (min-width:768px) {
          margin-left: -175x;
          margin-right: unset;
        }

        @media (max-width:1499px) and (min-width:768px) {
          margin-left: -134px;
          margin-right: unset;
        }

        @media (max-width:460px) {
          margin-left: -124px;
          margin-right: unset;
        }
      }
    }
  }

  .follower-wrap {
    .card-body {
      .customers {
        ul {
          li {
            +li {
              margin-right: -7%;
              margin-left: unset;
            }
          }
        }
      }
    }
  }

  // Dashboard-06

  .art-creator-table {
    &.recent-table {
      table {

        thead,
        tbody {
          tr {

            th,
            td {
              &:first-child {
                padding-right: 20px !important;
                padding-left: 8px;
              }

              &:last-child {
                padding-left: 20px !important;
                padding-left: 8px;
              }

              @media (max-width:1551px) and (min-width:1400px) {
                &:nth-child(2) {
                  padding-left: 20px;
                }
              }
            }
          }
        }

        &.artwork-table {

          thead,
          tbody {
            tr {

              th,
              td {
                @media (max-width:1249px) and (min-width:1200px) {
                  &:nth-child(2) {
                    padding-left: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .nft-table {
    &.recent-table {
      div.dt-container {
        .dt-layout-row {
          .dt-layout-cell {
            .dt-search {
              @media (max-width:991px) {
                right: 20px;
                left: unset;
              }
            }
          }
        }
      }

      table {
        tr {
          th {
            &:first-child {
              padding-left: 10px !important;
              text-align: center !important;
            }

            &:last-child {
              padding-right: 10px !important;
            }
          }

          th,
          td {
            &:first-child {
              padding-right: 20px !important;
              padding-left: 8px;
            }

            &:last-child {
              padding-left: 20px !important;
              padding-right: 8px;
            }
          }
        }
      }

      .product-content {
        .order-image {
          .mark-img {
            left: 0;
            right: unset;
          }
        }
      }
    }
  }

  .statistics-card {
    .card-header {
      a {
        i {
          transform: scaleX(-1);
        }
      }
    }
  }

  .arrow-round {

    .swiper-button-next,
    .swiper-button-prev {
      &::after {
        transform: scaleX(-1);
      }
    }
  }

  .total-balance {
    .card-body {
      .common-f-start {
        .d-flex {
          &:nth-of-type(2) {
            border-right: 1px solid var(--recent-dashed-border);
            padding-right: 22px;
            border-left: unset;
            padding-left: unset;
          }
        }
      }
    }
  }

  // Dashboard-07
  .student-leader-wrapper {
    .student-leader-content {
      .leader-content-height {
        span {
          padding-right: 0.25rem !important;
          padding-left: unset !important;
        }
      }
    }
  }

  .new-enroll-student {
    .recent-table {
      &.table-responsive {
        div.dt-container {
          .dt-layout-row {
            .dt-layout-cell {
              .dt-search {
                @media (max-width:1530px) {
                  right: 20px;
                  left: unset;
                }
              }
            }
          }
        }
      }

      table {

        thead,
        tbody {

          th,
          td {

            &:first-child {
              padding-right: 20px !important;
            }

            &:last-child {
              padding-left: 20px !important;
            }
          }
        }
      }
    }
  }

  .unpaid-fees-table {
    .recent-table {
      &.table-responsive {
        div.dt-container {
          .dt-layout-row {
            .dt-layout-cell {
              .dt-search {
                @media (max-width:1199px) {
                  right: 20px;
                  left: unset;
                }
              }
            }
          }
        }

        table {

          thead,
          tbody {

            th,
            td {
              &:first-child {
                padding-right: 20px;
              }

              &:last-child {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }
  }

  .top-student-table {
    .recent-table {
      &.table-responsive {
        div.dt-container {
          .dt-layout-row {
            .dt-layout-cell {
              .dt-search {
                @media (max-width:1199px) {
                  right: 20px;
                  left: unset;
                }
              }
            }
          }
        }
      }

      table {

        thead,
        tbody {

          th,
          td {

            &:first-child {
              padding-right: 20px;
            }

            &:last-child {
              padding-left: 20px;
            }
          }
        }
      }
    }
  }

  .sales-report {
    .attendance-data {
      ul {
        &.balance-data {
          @media (max-width:1660px) {
            right: 0;
            left: unset;
          }
        }
      }
    }
  }

  // list page
  .horizontal-list-wrapper {

    .list-group-horizontal-sm,
    .list-group-horizontal-md,
    .list-group-horizontal-lg,
    .list-group-horizontal-xl,
    .list-group-horizontal-xxl {
      .list-group-item {
        &:first-child {
          border-bottom-left-radius: 0;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          border-top-left-radius: 0;
        }

        &:last-child {
          border-top-right-radius: 0;
          border-bottom-left-radius: 6px;
          border-top-left-radius: 6px;
          border-bottom-right-radius: 0;
          border-left-width: 1px;
        }
      }
    }
  }

  // image cropper page
  .img-cropper {
    .docs-buttons {

      >.btn,
      >.form-control {
        margin-right: unset;
        margin-left: 10px;
      }
    }

    .btn-group {
      margin-right: 0;
      margin-left: 10px;

      button {
        &:last-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 0.25rem !important;
          border-bottom-left-radius: 0.25rem !important;
        }

        &:first-child {
          border-top-right-radius: .25rem;
          border-bottom-right-radius: .25rem;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .docs-toggles {
    .btn-group {
      .btn {
        border-radius: 0;

        &:last-child {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }

        &:first-child {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
  }

  .btn-group {
    >.btn:not(:last-child):not(.dropdown-toggle) {
      >.btn-group:not(:last-child)>.btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .jsgrid-button {
    +.jsgrid-button {
      margin-right: 5px;
      margin-left: unset;
    }
  }

  .list-group {
    padding-right: 0;

    i,
    img {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  // authentication page
  .authentication-box {
    text-align: right;
  }

  // mega options page
  .mega-horizontal {
    .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }
  }

  // navs page
  .navs-icon {
    svg {
      margin-left: 10px;
      margin-right: unset;
    }

    .fa {
      margin-left: 10px;
      margin-right: unset;
    }
  }

  .nav-list {
    .nav-list-disc {
      text-align: right;
      padding-right: 0;
    }
  }

  .ps-4 {
    padding-right: 30px !important;
    padding-left: 0 !important;
  }

  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
    left: 77px;
    right: unset;
  }

  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
    left: 77px;
    right: unset;
  }

  .navs-dropdown {
    .onhover-show-div {
      .navs-icon {
        li {
          text-align: right;
        }
      }
    }
  }

  .navs-icon.default-according.style-1 {
    li {
      button[aria-expanded="true"] {
        &:before {
          left: 2px;
          right: unset;
        }
      }

      button[aria-expanded="false"]:before {
        left: 2px;
        right: unset;
      }
    }
  }

  // search page css
  .search-form {
    .form-group {
      &:after {
        right: 53px;
        left: unset;
      }

      &:before {
        right: 82px;
        left: unset;
      }
    }
  }

  .toolbar-box {
    .ql-toolbar {
      display: flex;
      flex-wrap: wrap;
    }

    .ql-container {
      .ql-tooltip {
        left: unset;
        right: 16px !important;
      }
    }
  }

  // list-product page
  .list-product-view {
    .list-product {
      .recent-table {
        div.dt-container {
          .dt-layout-row {
            .dt-search {
              right: unset;
              left: 214px;
            }
          }
        }

        table {

          thead,
          tbody {
            tr {

              th,
              td {
                &:first-child {
                  padding-right: 20px;
                  padding-left: 8px;
                }

                &:last-child {
                  padding-left: 20px;
                  padding-right: 8px;
                }

              }
            }
          }

          thead {
            tr {
              th {
                &:first-child {
                  text-align: center !important;
                }
              }
            }
          }
        }
      }
    }
  }

  // category page
  .e-category {
    .form-select {
      padding: .375rem .75rem .375rem 2.25rem;
    }

    .main-divider {
      .divider-body {
        &::before {
          left: unset;
          right: 0;
        }

        h6 {
          padding-right: unset;
          padding-left: 12px;
        }
      }
    }

    .list-product {
      &.list-category {
        div.dt-container {
          .dt-layout-row {
            .dt-search {
              right: unset;
              left: 214px;

              @media (max-width:640px) {
                right: 20px;
              }
            }
          }
        }
      }

      table {
        thead {
          tr {
            th {
              &:first-child {
                text-align: center !important;
              }
            }
          }
        }

        thead,
        tbody {
          tr {

            th,
            td {
              &:first-child {
                padding-left: 10px;
                padding-right: 20px;
              }

              &:last-child {
                padding-right: 10px;
                padding-left: 20px;
              }
            }
          }
        }
      }
    }
  }

  // product-page
  .product-page-details {
    span {
      padding-right: 15px;
      padding-left: 0;
    }
  }

  .m-checkbox-inline {
    label {
      margin-left: 20px;
      margin-right: 0;
    }
  }

  .form-label-align-right {
    label {
      text-align: left;
    }
  }

  // rating page
  .br-theme-bars-reversed,
  .br-theme-bars-movie,
  .br-theme-bars-1to10,
  .br-theme-bars-square {
    .br-widget {
      .br-current-rating {
        float: right;
      }

      a {
        float: right;
      }
    }
  }

  .rating-stars-2 {

    #star-1,
    #star-2,
    #star-3,
    #star-4,
    #star-5 {
      &:checked {
        ~.main-emoji-text {
          .emoji-msg {
            &::before {
              padding-right: unset;
              padding-left: 12px;
            }
          }
        }
      }
    }
  }

  // ribbons page
  .ribbon-left {
    right: auto;
    left: -2px;
  }

  .ribbon-vertical-left {
    right: auto;
    left: 12px;
  }

  .ribbon-vertical-right {
    right: auto;
    left: 12px;
  }

  // search page
  .star-ratings {
    ul {
      &.search-info {
        padding-right: 0;

        li {
          &+li {
            border-right: 1px solid #a5afc6;
            border-left: unset;
            padding-right: 8px;
            padding-left: 0;
            margin-right: 8px;
            margin-left: 0;
          }
        }
      }
    }
  }

  // select 2 page
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__arrow {
        left: 10px !important;
        right: unset !important;
      }
    }

    .select2-selection--multiple {
      .select2-selection__choice {
        float: right;
      }
    }
  }

  .selection {
    .select2-selection {
      .select2-search__field {
        text-align: right;
      }
    }
  }

  .select2-results__option {
    text-align: right;
  }

  .editor-statusbar {
    text-align: left;

    span {
      margin-right: 1em;
      margin-left: unset;
    }
  }

  .CodeMirror-scroll {
    margin-left: -30px;
    margin-right: unset;
  }

  .primary-color,
  .yellow-color {
    ul {
      padding-right: 0;
    }
  }

  .list-with-icon {
    .list-group {
      i {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  // Scrollable
  .ps-scrollbar-y-rail {
    right: unset !important;
    left: 3px !important;
  }

  // Tree view
  .stackable-list {
    .list-group {
      i {
        transform: scaleX(-1);
      }
    }
  }


  // steps page
  .u-step-desc {
    text-align: right;
  }

  .u-step-number {
    right: 20px;
    left: unset;

    &~.u-step-desc {
      margin-right: 50px;
    }
  }

  .u-pearl {
    &:after {
      left: 0;
      right: unset;
    }

    &:before {
      right: 0;
      left: unset;
    }
  }

  .u-step-icon {
    float: right;
    margin-left: .5em;
    margin-right: unset;
  }

  .card-header.note-toolbar {
    .note-color {
      .dropdown-toggle {
        padding-right: 5px;
      }
    }

    .form-group {
      &.draggable {
        text-align: right;
      }
    }

    .form-check {
      padding-right: 24px;
      padding-left: unset;
    }

    form {
      .custom-control {
        padding-right: 24px;
        padding-left: unset;

        .custom-control-label {
          &::after {
            right: 0;
            left: unset;
          }

          &::before {
            right: 0;
            left: unset;
          }
        }
      }
    }
  }

  // add-product 
  .common-form {
    .product-buttons {
      .btn {
        &:first-child {
          margin-right: unset;
          margin-left: 12px;

          svg {
            transform: scale(-1);
            margin-right: unset;
            margin-left: 12px;
          }
        }

        &:last-child {
          svg {
            transform: scale(-1);
            margin-left: unset;
            margin-right: 6px;
          }
        }
      }
    }
  }

  // seller details 
  .review-box {
    .seller-profile {
      .accordion-item {
        .accordion-body {
          .policies-wrapper {
            .ql-toolbar {
              display: block;

              .ql-formats {
                margin-right: unset;
                margin-left: 15px;
              }
            }
          }
        }
      }
    }
  }

  .vendor-selling-table,
  .seller-order-table {
    .recent-table {
      div.dt-container {
        .dt-layout-row {
          .dt-search {
            right: unset !important;
            left: 20px !important;
          }
        }
      }

      .table {

        thead,
        tbody {
          tr {

            th,
            td {
              &:first-child {
                padding-right: 20px;
                padding-left: 8px;
                text-align: center !important;
              }

              &:last-child {
                padding-left: 20px;
                padding-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  .seller-details-table {
    .recent-table {
      div.dt-container {
        .dt-layout-row {
          .dt-search {
            left: 214px !important;
            right: unset;
          }
        }
      }

      .table {

        thead,
        tbody {
          tr {

            th,
            td {
              &:first-child {
                padding-right: 20px;
                padding-left: 8px;
                text-align: center !important;
              }

              &:last-child {
                padding-left: 20px;
                padding-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  .seller-wrapper {
    .right-vendor {
      .common-searchbox {
        .form-control {
          border-right: 0;
          border-left: unset;
          padding-right: 0;
          padding-left: unset;
        }
      }
    }
  }


  // input group page
  .dropdown-toggle::after {
    margin-right: 0.255em;
    margin-left: unset;
  }

  // checkbox & radios page
  .checkbox {
    label {
      padding-right: 16px;
      padding-left: unset;

      &::before {
        right: 0;
        left: unset;
        margin-right: -16px;
        margin-left: unset;
      }
    }
  }

  .checkbox-wrapper,
  .radio-wrapper {
    li {
      label {
        padding-right: 0;
      }
    }
  }

  .radio {
    label {
      &::after {
        right: 5px;
        left: unset;
        margin-right: -20px;
        margin-left: unset;
      }
    }
  }

  // tour page
  .introjs-tooltip {
    right: 0;
    left: unset;
  }

  .introjs-helperNumberLayer {
    right: -16px;
    left: unset;
  }

  .introjs-tooltipReferenceLayer {
    .introjs-tooltip {
      .introjs-tooltiptext {
        text-align: right;
      }

      .introjs-tooltipbuttons {
        text-align: left;

        .introjs-skipbutton {
          margin-left: 5px;
          margin-right: unset;
        }

        .introjs-nextbutton {
          -webkit-border-radius: 0.2em 0 0 0.2em;
          -moz-border-radius: 0.2em 0 0 0.2em;
          border-radius: 0.2em 0 0 0.2em;
        }
      }
    }
  }

  .hovercard {
    .follow {
      .border-end {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-right: unset !important;
      }
    }
  }

  .list-inline-item {
    &:not(:last-child) {
      margin-left: 0.5rem;
      margin-right: unset;
    }
  }

  .like-comment {
    ul {
      &.list-inline {
        padding-right: 0;
      }
    }
  }

  .typeahead {
    text-align: right;
  }

  .listing {
    ul {
      padding-right: 0;

      .icofont {
        float: right;
      }
    }
  }

  // gallery page
  .gallery {
    .hover-5 {
      img {
        margin-right: 30px;
      }

      &:hover {
        img {
          margin-right: 0;
        }
      }
    }
  }

  .lg-outer {
    text-align: right;
  }

  .lg-toolbar .lg-icon {
    float: left;
  }

  #lg-counter {
    padding-right: 20px;
    padding-left: unset;
    float: right;
  }

  // chart widget page
  .chart-widget-top {
    .text-end {
      text-align: left !important;
    }

    .num {
      .ms-1 {
        margin-right: 0.25rem !important;
      }
    }
  }

  .bar-chart-widget {
    .earning-details {
      i {
        left: -240px;
      }
    }

    .bottom-content {
      .b-r-light {
        border-left: 1px solid #eeeeee !important;
        border-right: unset !important;
      }

      .num {
        .ms-1 {
          margin-right: 0.25rem !important;
        }
      }
    }
  }

  // dashboard-08

  .customer-sticky {
    .common-search {
      .form-control {
        border-right: unset;
        border-left: var(--bs-border-width) solid var(--bs-border-color);
      }
    }
  }

  // Dashboard-9
  .compare-order {
    .card-body {
      .user-growth {
        margin-right: unset;
        margin-left: 4px;
      }
    }
  }

  .crm-card {
    .crm-img {
      right: unset;
      left: 12px;

      @media (max-width:1874px) and (min-width:1820px) {
        left: 12px;
        right: unset;
      }

      @media (max-width:1819px) and (min-width:1626px) {
        left: 8px;
        right: unset;
      }

      @media (max-width:1625px) and (min-width:1521px) {
        left: 12px;
        right: unset;
      }

      @media (max-width:1520px)and (min-width:1400px) {
        left: 4px;
        right: unset;
      }

      @media (max-width:850px) and (min-width:768px) {
        left: 8px;
        right: unset;
      }

      @media (max-width:416px) {
        left: 8px;
        right: unset;
      }
    }
  }

  .revenue-chart-container {
    margin: -23px -16px -26px -10px;
  }

  .finance-wrapper {
    .common-flex {
      h6 {
        &:last-child {
          border-right: 1px solid var(--chart-dashed-border);
          border-left: unset;
          padding-right: 16px;
          padding-left: unset;

          @media (max-width:338px) and (min-width:323px) {
            padding-right: 6px;
            padding-left: unset;
          }

          @media (max-width:322px) {
            border-right: unset;
            border-left: unset;
            padding-right: 0;
            padding-left: unset;
          }
        }
      }
    }
  }

  .deal-table {
    &.recent-table {

      thead,
      tbody {
        tr {

          th,
          td {
            &:first-child {
              padding-right: 20px !important;
              padding-left: 8px;
            }

            @media (max-width:1704px) and (min-width:1400px) {


              &:nth-child(2) {
                padding-right: 20px;
              }
            }

            &:last-child {
              padding-left: 20px !important;
              padding-right: 8px;
            }

            @media (max-width:1591px) and (min-width:1400px) {
              &:nth-child(2) {
                padding-right: 20px;
              }
            }
          }
        }
      }
    }
  }

  // Leads table
  .lead-table {
    &.recent-table {
      &.table-responsive {
        div.dt-container {
          .dt-layout-row {
            .dt-layout-cell {
              .dt-search {
                left: 60px;
                right: unset;
              }
            }
          }
        }
      }

      thead,
      tbody {
        tr {

          th,
          td {

            &:first-child {
              padding-right: 20px !important;
              padding-left: 8px;
            }

            &:last-child {
              padding-left: 20px !important;
              padding: 8px;
            }
          }
        }
      }
    }
  }

  // Lead source 
  .main-lead-source {
    margin-right: -100px;
    margin-left: unset;

    .lead-source-1 {
      margin: 40px 20px 80px 0px;

      @media (max-width:1610px) and (min-width:1411px) {
        margin: 60px 20px 100px 0px;
      }

      @media (max-width:1410px) and (min-width:1400px) {
        margin: 70px 20px 120px 0px;
      }

      @media (max-width:1399px) and (min-width:1211px) {
        margin: 30px 20px 80px 0px;
      }

      @media (max-width:1210px) {
        margin: 40px 20px 90px 0px;
      }

      @media (max-width:767px) {
        margin: 20px 20px 70px 0px;
      }

      @media (max-width:585px) and (min-width:576px) {
        margin: 42px 32px 92px 0px;
      }
    }

    .lead-source-2 {
      left: -35%;
      right: unset;

      @media (max-width:1410px) and (min-width:1400px) {
        left: -42%;
        right: unset;
      }
    }
  }

  .lead-source-3 {
    left: -90%;
    right: unset;
    transform: translate(50%, 50%);

    @media (max-width:1599px) {
      left: -85%;
      right: unset;
    }
  }

  // Sales
  .sales-data {
    @media (max-width:1490px) and (min-width:1431px) {
      justify-content: flex-end;
    }

    @media (max-width:635px) and (min-width:576px) {
      justify-content: flex-end;
    }
  }

  // Dashboard-10
  .dashboard-10 {
    .card {
      &.heading-space {
        .header-top {
          @media (max-width:420px) {
            margin-right: 0 !important;
            margin-left: unset !important;
          }
        }
      }
    }
  }

  .page-analytics-table {
    .recent-table {
      div.dt-container {
        .dt-layout-row {

          .dt-layout-cell {
            .dt-search {
              left: 60px;
              right: unset;

              @media (max-width:1500px) {
                right: 20px;
                left: unset;
              }

              @media (max-width:420px) {
                right: unset;
                left: -130px;
              }
            }
          }

          &:first-child {
            @media (max-width:420px) {
              .dt-length {
                right: 20px;
                left: unset;
              }
            }
          }
        }
      }

      .table {

        thead,
        tbody {
          tr {

            th,
            td {
              &:first-child {
                padding-right: 20px !important;
              }

              &:last-child {
                padding-left: 20px !important;
              }
            }
          }
        }
      }
    }
  }

  .session-progress {
    .progress {

      .small-progressbar {
        .animate-circle {
          left: -16px;
          right: unset;
        }
      }

      .small-progressbar {
        &::after {
          left: -9px;
          right: unset;
        }
      }

      .progress-label {
        left: -20px;
        right: unset;

        @media (max-width:1399px) {
          left: -16px;
          right: unset;
        }
      }
    }
  }

  .analytics-view-wrapper {
    margin: -34px px -30px -10px;
  }

  .visit-duration-wrapper {
    margin: -34px -10px -30px 35px;
  }

  .session-browser-table {
    .table-responsive {
      .table {

        thead,
        tbody {
          tr {

            th,
            td {

              &:first-child {
                padding-right: 20px !important;
                padding-left: 8px;
              }

              &:last-child {
                padding-left: 20px !important;
                padding-left: 8px;
              }
            }
          }
        }
      }
    }
  }

  // Dashboard-11
  .attendance-table {
    &.recent-table {
      div.dt-container {
        .dt-layout-row {
          .dt-search {
            @media (max-width:1399px) {
              right: 20px;
              left: unset;
            }
          }
        }
      }

      table {

        thead,
        tbody {
          tr {

            th,
            td {

              &:first-child {
                padding-right: 20px !important;
              }

              &:last-child {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }
  }

  .birthday-cards {
    .swiper {
      .swiper-slide {
        .birthday-usercard {
          .btd-user {
            .verified-user {
              left: 0;
              right: unset;
            }
          }
        }
      }
    }

    .send-wishes {
      i {
        transform: scaleX(-1);
      }
    }
  }

  .project-analytics {
    .project-analytics-wrapper {
      margin: 0 -10px -20px 0px;
    }
  }

  .project-finish-card {
    .project-right-content {
      right: unset;
      left: -16px;
    }
  }

  .leave-request-table {
    &.table-responsive {
      .table {

        thead,
        tbody {

          th,
          td {
            &:first-child {
              padding-right: 20px !important;
            }

            &:last-child {
              padding-left: 20px !important;
            }

            @media (max-width:1635px) and (min-width:1400px) {

              &:nth-child(2) {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }
  }

  .schedule-calender {
    .tab-content {
      .activity-update {
        >li {
          >div {
            border-radius: 0px 6px 6px 0px;

            @media (max-width:1490px) and (min-width:1400px) {
              padding-right: 20px;
              padding-left: unset;
            }

            @media (max-width:500px) {
              padding-right: 20px;
              padding-left: unset;
            }
          }

          .left-schedule {
            margin-right: 14px;
            margin-left: unset;

            @media (max-width:1490px) and (min-width:1400px) {
              margin-right: 0;
              margin-left: unset;
            }

            @media (max-width:500px) {
              margin-right: 0;
              margin-left: unset;
            }

            .customers {
              padding-left: 6px;
              padding-right: unset;

              ul {
                li {
                  +li {
                    margin-right: -16px;
                    margin-left: unset;
                  }
                }
              }
            }

            .schedule-name {
              border-right: 1px solid rgba($badge-light-color, 80%);
              border-left: unset;
              padding-right: 6px;
              padding-left: unset;
            }
          }
        }
      }
    }
  }

  .employee-list-table {
    &.recent-table {
      div.dt-container {
        .dt-layout-row {
          .dt-layout-cell {
            .dt-search {
              @media (max-width:1399px) {
                right: 20px;
                left: unset;
              }
            }
          }
        }
      }
    }
  }

  .html-expert-table {
    .dt-container {
      .dt-buttons {
        float: none;
      }

      .dt-search {
        text-align: left;
      }
    }
  }

  // dashboard project page
  .chart-vertical-center {

    #myDoughnutGraph,
    #myPolarGraph {
      width: auto !important;
    }
  }

  div {
    &.dt-buttons {
      float: right;
    }
  }

  .dt-button-collection {
    left: unset !important;
    right: 0 !important;
  }

  // dropzone page
  .dropzone {
    .dz-preview {

      .dz-error-mark,
      .dz-success-mark {
        right: 50%;
        margin-right: -27px;
        margin-left: unset;
        left: unset;
      }

      .dz-filename,
      .dz-details,
      .dz-remove {
        text-align: right;
      }
    }
  }

  // footer page
  .page-wrapper {
    .page-body-wrapper {
      footer {
        margin-right: 265px;
        margin-left: unset;

        p {
          i {
            margin-right: 5px;
            margin-left: unset;
          }
        }
      }
    }
  }

  .footer-copyright {
    text-align: right;
  }

  .footer-links {
    text-align: left;
  }

  .page-item {
    &:first-child {
      .page-link {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }

    &:last-child {
      .page-link {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  // popover page
  .popover.top,
  .popover.bottom {
    >.arrow:after {
      margin-right: -10px;
      margin-left: unset;
    }
  }

  // touchspin page
  .bootstrap-touchspin {
    >.input-group-append {

      >.btn,
      >.input-group-text {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    >.form-control,
    >.custom-select {
      &:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .rounded-touchspin {
      .btn-touchspin {
        i {
          transform: scaleX(-1);
        }
      }
    }
  }

  // typahead page
  .typeahead {
    span {
      &.twitter-typeahead {
        .tt-suggestion {
          text-align: right;
        }
      }
    }

    .league-name {
      text-align: right;
      margin-right: 5px;
    }
  }

  // call-chat page
  .follow {
    .text-md-right {
      text-align: left !important;
    }
  }

  // vector map page
  .jvectormap-zoomin,
  .jvectormap-zoomout,
  .jvectormap-goback {
    right: 10px;
    left: unset;
  }

  //customizer rtl css
  .customizer-links {
    right: unset;
    left: 0;

    >.nav {
      padding-right: 10px;
    }

    .nav-link {
      &:after {
        right: -10px;
        left: unset;
        border-width: 5px 5px 5px 0;
        border-left-color: unset;
        border-right-color: lighten($primary-color, 25%);
      }

      span {
        right: unset;
        left: -60px;
      }

      &:hover {
        span {
          left: 48px;
        }
      }
    }

    &.open {
      right: unset;
      left: 330px;
      border-radius: 0 8px 8px 0;
    }
  }

  .customizer-contain {
    width: 330px;
    right: unset;
    left: -330px;

    &.open {
      right: unset;
      left: 0px;
    }

    .customizer-header {
      .icon-close {
        left: unset;
        right: 30px;
      }
    }
  }

  // boxes layout rtl css
  .box-layout {
    &.page-wrapper.horizontal-wrapper {
      .page-header {
        padding-right: 250px;
        padding-left: unset;

        &.open {
          padding-right: 0;
        }
      }
    }

    &.page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          .page-body {
            margin-left: unset;
            margin-right: 230px;
          }

          .sidebar-wrapper {
            border-radius: 0 10px 10px 0;

            &.close_icon {
              ~footer {
                width: 1280px;
                left: 50%;
                padding-inline: 0;
              }
            }
          }

          footer:not(.kanban-container footer) {
            padding-left: unset;
            padding-right: 230px;
          }
        }

        .page-header {
          margin-left: auto;
          margin-right: auto;
          padding-left: unset;
          padding-right: 230px;

          &.close_icon {
            padding-right: 100px;
          }

          .header-wrapper {
            border-radius: 10px 0px 0px 0px;
          }
        }
      }

      .page-header {
        &.close_icon {
          padding-left: unset;
          padding-right: 80px !important;
          margin-left: auto;
        }
      }
    }
  }

  body.dark-only {
    .custom-card {
      .card-footer {
        >div {
          +div {
            border-right: 1px solid $dark-card-border;
          }
        }
      }
    }

    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          .card:not(.email-body) {

            .b-r-light,
            .border-right {
              border-right: none !important;
              border-left: 1px solid $dark-card-border !important;
            }
          }
        }
      }

      &.compact-wrapper {
        .page-header {
          .header-wrapper {
            .toggle-sidebar {
              border-left: 1px solid $dark-card-border;
              border-right: unset;
            }
          }
        }
      }
    }

    .social-chat {
      .flex-grow-1 {
        &:after {
          border-right: unset;
          border-left: 7px solid $dark-card-background;
        }
      }
    }

    .contacts-tabs {
      .nav-pills {
        border-right: unset;
        border-left: 1px solid $dark-card-border;
      }
    }
  }

  // responsive css
  @media screen and (max-width: 1660px) {

    // video chat page
    .caller-img {
      left: unset;
      right: 15px;
    }
  }

  @media only screen and (max-width: 1366px) {
    .profile-dropdown {
      &.onhover-show-div {
        right: unset !important;
        left: 0 !important;
      }
    }

    .product-wrapper {
      .product-grid {
        .feature-products {
          margin-right: 0;
        }
      }
    }

    .product-grid {
      margin-right: 0;
    }

    .d-none-productlist {
      margin-left: 10px;
      margin-right: unset;
    }

    .photos {
      ul {
        li {
          &:nth-child(3n) {
            margin-left: 15px;
            margin-right: unset;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .float-xl-end {
      float: left !important;
    }

    // vertical page
    .pixelstrap {
      ul {
        a {

          &:hover,
          &:focus,
          &:active,
          &.highlighted {
            .sub-arrow {
              left: 3px;
              right: unset;
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .text-lg-start {
      text-align: right !important;
    }
  }

  @media only screen and (max-width: 1199.98px) {
    .jkanban-container {
      .btn {
        margin-left: unset;
        margin-right: 20px;
      }
    }

    .link-section>div>h6:before {
      right: unset;
      left: 10px;
    }

    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            >ul {
              li {
                .submenu {
                  li {
                    &:first-child {
                      padding-right: 15px !important;
                      padding-left: 15px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 45px;
          }
        }
      }
    }

    .email-wrap {
      .row {
        .col-xl-6 {
          padding-right: 15px;
        }

        .col-xl-3 {
          +.col-xl-3 {
            padding-left: 15px;
          }
        }
      }

      .email-app-sidebar {
        .main-menu {
          &>li {
            text-align: right;

            a {
              i {
                margin-right: unset;
                margin-left: 10px;
              }
            }
          }
        }

        ul {
          padding-right: 0;
        }
      }



      .email-right-aside {
        .email-body {
          .pe-0 {
            padding-right: unset !important;
          }
        }

        .radius-left {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-radius: 4px;
        }
      }
    }

    .chat-menu-icons {
      text-align: left;
    }

    .chat-menu {
      left: 0;
      right: unset;
    }
  }

  @media only screen and (max-width: 991.98px) {
    .todo {
      .todo-list-wrapper {
        .mark-all-tasks {
          left: 20px;
        }
      }
    }

    .page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          div.sidebar-wrapper {
            &.close_icon {
              transform: translate(285px);

              &:hover {
                transform: translate(285px);
              }
            }

            .logo-wrapper {
              .back-btn {
                float: left;
                transform: rotate(180deg);
                left: 26px;
                right: unset;
              }
            }
          }
        }

        .page-header {
          .header-wrapper {
            .toggle-sidebar {
              margin-right: 0;
              border-right: none;
              padding-right: 0;
              border-left: 1px solid #ddd;
              margin-left: 15px;
              padding-left: 15px;

            }
          }
        }
      }

      &.compact-sidebar {
        .page-header {
          margin-right: 0;
        }
      }

      &.material-icon {
        .page-header {
          padding-right: 0;
        }

        .page-body-wrapper {
          .page-body {
            padding-right: 15px;
          }
        }
      }
    }

    .faq-accordion {
      .faq-header {
        svg {
          left: 20px;
        }
      }
    }

    .page-wrapper {
      .page-header {
        .header-logo-wrapper {
          .logo-wrapper {
            img {
              margin-left: 15px;
              margin-right: unset;
            }
          }
        }
      }

    }

    .page-body-wrapper {

      .page-body,
      footer {
        margin-right: 0 !important;
      }
    }

    .main {
      .langChoice {
        left: 20px;
      }
    }

    .page-wrapper {
      .page-body-wrapper {
        .page-title {
          .breadcrumb {
            margin-right: 0;
          }
        }

        .page-body {
          min-height: calc(100vh - 60px);
        }
      }

      .page-header {
        margin-right: 0;
      }

      &.compact-wrapper {
        .page-header {
          margin-right: 0 !important;
          margin-left: unset !important;
        }

        .page-body-wrapper {
          &.sidebar-wrapper {

            &.close_icon {
              transform: translate(285px);
            }
          }
        }
      }
    }

    .bookmark {
      ul {
        li {
          .search-form {
            .form-control-search {
              left: -127px;
            }
          }
        }
      }
    }

    .card {
      .card-header {
        .card-header-right {
          left: 15px;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .page-wrapper {
      &.compact-wrapper {
        &.material-type {
          .page-body-wrapper {
            div.sidebar-wrapper {
              left: unset;
              right: 0;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .float-md-end {
      float: left !important;
    }

    .income-wrap {
      .ps-md-0 {
        padding-left: 30px !important;
        padding-right: 0 !important;
      }
    }

    .text-md-end {
      text-align: left !important;
    }

    .offset-md-3 {
      margin-right: 25%;
      margin-left: unset;
    }

    .text-md-start {
      text-align: right !important;
    }

    .ecommerce-widget {
      .text-md-end {
        text-align: left !important;

        ul {
          text-align: left;
        }
      }
    }
  }

  @media only screen and (max-width: 767.98px) {
    .list-persons {
      .profile-mail {
        .email-general {
          ul {
            padding-left: 0;
          }
        }
      }
    }

    .product-wrapper {
      &.sidebaron {
        .product-grid {
          .product-wrapper-grid {
            margin-right: 0;
          }
        }
      }
    }

    // .product-sidebar {
    //   // transform: translateX(300px) scaleX(1);

    //   &.open {
    //     transform: translateX(0px) scaleX(1);
    //   }
    // }

    .feature-products {
      .filter-toggle {
        margin-right: 10px;
        margin-left: unset;
      }
    }

    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 25px !important;
          }
        }
      }
    }

    .ecommerce-widget {
      .text-md-right {
        text-align: left !important;
      }
    }

    .bar-chart-widget {
      .earning-details {
        i {
          left: -147px !important;
        }
      }
    }
  }

  @media (min-width: 576px) {
    .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }

    .text-sm-end {
      text-align: left !important;
    }

    .float-sm-end {
      float: left !important;
    }

    .me-sm-2 {
      margin-left: 0.5rem !important;
      margin-right: unset !important;
    }
  }

  @media only screen and (max-width: 575.98px) {

    .todo {
      .todo-list-wrapper {
        .mark-all-tasks {
          left: 15px;
        }
      }
    }

    .list-persons {
      .nav-pills {
        .nav-link {
          .d-flex {
            .flex-grow-1 {
              text-align: right;
            }
          }
        }
      }
    }

    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            >ul {
              li {
                margin-right: unset;
                margin-left: 1px;
              }
            }
          }

          .search-full {
            .form-group {
              .close-search {
                left: 0;
              }
            }
          }
        }
      }

      &.compact-wrapper,
      &.compact-sidebar {
        .page-header {
          .header-wrapper {
            .toggle-sidebar {
              margin-left: 8px;
              padding-left: 8px;
              margin-right: unset;
              padding-right: unset;
              border-right: unset;
              border-left: 1px solid #ddd;
            }
          }
        }
      }
    }

    .translate_wrapper {
      &.active {
        .more_lang {
          &.active {
            left: unset;
            right: 12px;
          }
        }
      }
    }

    .page-header {
      .header-wrapper {
        .nav-right {
          >ul {
            >li {
              &:last-child {
                padding-right: 10px;
              }
            }
          }

          &.right-header {
            ul {
              &.notification-dropdown {
                left: unset !important;
                right: 15px !important;
              }
            }
          }
        }
      }
    }

    .faq-accordion {
      .faq-header {
        svg {
          left: 15px;
        }
      }
    }

    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 15px !important;

            li {
              margin-left: 0px;
            }
          }
        }
      }
    }

    .main {
      .langChoice {
        left: 20px;
      }
    }

    .page-wrapper {
      .search-form {
        .form-group {
          margin-left: 0;
        }
      }
    }

    .card-body {
      .value-left {
        margin-left: 15px;
      }
    }
  }

  // Common Datatables 
  .default-dashboard,
  .dashboard-2,
  .dashboard-3,
  .dashboard-4,
  .dashboard-5,
  .dashboard-6,
  .dashboard-7,
  .dashboard-09,
  .dashboard-10,
  .dashboard-11,
  .main-scope-project,
  .e-category,
  .list-product-view,
  .seller-details-wrapper,
  .common-order-history,
  .common-cart,
  .overall-budget {
    .heading-space {
      .card-header {
        .header-top {
          margin-right: 74px;
          margin-left: unset;
        }
      }
    }

    div.dt-container {
      .dt-layout-row {
        .dt-length {
          right: 20px;
          left: unset;
        }

        .dataTable {
          thead {
            tr {
              th {
                text-align: right;

                &:not(:first-child) {
                  padding-left: 28px !important;
                }
              }
            }
          }

          &.table-sm {
            thead {
              tr {
                th {

                  &.dt-orderable-asc,
                  &.dt-orderable-desc,
                  &.dt-ordering-asc,
                  &.dt-ordering-desc {
                    padding-left: 20px;

                    span {
                      &.dt-column-order {
                        left: 5px;
                        right: unset;
                      }
                    }
                  }
                }
              }
            }
          }

          thead {
            tr {
              th {

                &.dt-orderable-asc,
                &.dt-orderable-desc,
                &.dt-ordering-asc,
                &.dt-ordering-desc {
                  padding-left: 28px;
                  padding-right: 10px;

                  span {
                    &.dt-column-order {
                      left: 12px;
                      right: unset;

                      &::before,
                      &::after {
                        right: 0;
                        left: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .dt-layout-cell {
          .dt-search {
            left: -10px;
            right: unset;

            @media (max-width:640px) {
              right: 20px;
              left: unset;
            }

            label {
              margin-left: 6px;
              margin-right: unset;
            }
          }
        }

        .dt-paging {
          text-align: start;

          .dt-paging-button {
            margin-left: 12px;
            margin-right: unset;

            @media (max-width:767px) {
              margin-left: 8px;
              margin-right: unset;
            }

            &:last-child {
              margin-left: 0;
              margin-right: unset;
            }
          }
        }

        .dt-select {
          .dt-select-checkbox {

            &:indeterminate {
              &::after {
                right: 4px;
                left: unset;
              }
            }

            &:checked {

              &::after {
                margin-right: -4px;
                margin-left: unset;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width:575.98px) {
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            .onhover-show-div {
              right: unset !important;
              left: 12px !important;
            }
          }
        }
      }
    }
  }


  @media only screen and (max-width: 480px) {
    .knob-chart {
      .chart-clock-main {
        width: unset;

        .clock-small {
          right: 32px;
        }

        .clock-large {
          right: 12px;
          left: unset;
        }

        .clock-medium {
          right: 32px;
          left: unset;
        }
      }
    }
  }

  @media only screen and (max-width: 420px) {
    .page-wrapper {
      &.compact-sidebar {
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                li {

                  .sidebar-submenu {
                    right: 112px;
                  }
                }
              }
            }
          }
        }
      }

      &.compact-small {
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                li {

                  .sidebar-submenu {
                    right: 80px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 360px) {
    .social-app-profile {
      .hovercard {
        .user-image {
          .avatar {
            img {
              width: 85px;
              height: 85px;
            }

            margin-top: -47px;
          }
        }
      }
    }

    .social-chat {
      .other-msg {
        margin-right: 15px;
      }
    }
  }

  @media screen and (max-width: 1460px) {
    .sidebar-wrapper {
      .sidebar-main {
        .sidebar-links {
          .simplebar-wrapper {
            .simplebar-mask {
              .simplebar-content-wrapper {
                .simplebar-content {
                  >li {
                    margin-left: 8px;
                    margin-right: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/**=====================
    5.8 Rtl CSS Ends
==========================**/