/**=====================
    2.13 Data picker CSS Start
==========================**/
.datepicker {
  z-index: 99;
}

.datepicker--day-name {
  color: $primary-color;
  font-weight: bold;
}

.datepicker--cell {
  &.-current- {
    color: $black;
    border-radius: 5px;
    font-weight: bold;
    border: 2px solid var(--theme-default);
  }

  &.-focus- {
    background: var(--theme-default);
    color: $white;
  }

  &.-selected- {
    background: var(--theme-default) !important;
  }

  &-day {
    width: 14.2857142857%;
  }
}

.datepickers-container,
.bootstrap-datetimepicker-widget {
  z-index: 7;
}

.datetime-picker {
  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: auto;
  }

  .input-group-text i {
    line-height: 1.3;
  }
}

.bootstrap-calendar {
  .form-control {
    font-size: 14px;
    color: $dark-gray;
  }
}

// timepicker
.clockpicker-popover {
  border: none;
  box-shadow: $card-box-shadow;
  z-index: 7;

  .popover-content {
    background-color: rgba($primary-color, 0.05);
  }
}

.clockpicker-canvas-bg {
  fill: lighten($primary-color, 38%);
}

.clockpicker-tick {
  &:hover {
    color: inherit;
    background-color: lighten($primary-color, 38%);
  }
}

// calendar
.calendar-basic {
  .md-sidebar-aside {
    background-color: $white;
  }

  #external-events {
    padding: 20px;
    border: 1px solid $light-background;

    h4 {
      margin-bottom: 14px;
    }

    p {
      margin-top: 12px;
    }

    .fc-h-event {
      padding: 12px;
      background-color: var(--theme-default);
      border: 1px solid var(--theme-default);

      +.fc-h-event {
        margin-top: 10px;
      }

      .fc-event-main {
        font-size: 13px;
      }
    }
  }

  .calendar-default {
    .fc-event-main-frame {
      padding: 2px 6px;
      font-size: 13px;
    }

    .fc {
      .fc-highlight {
        background: $light-primary;
      }
    }

    .fc-daygrid-dot-event {
      font-size: 13px;

      .fc-event-title {
        text-overflow: ellipsis;
      }
    }

    .fc {
      td[role="presentation"] {
        border: 0;
      }

      .fc-daygrid-day-number,
      .fc-col-header-cell-cushion {
        color: $theme-body-font-color;
        font-weight: 500;
      }

      .fc-button {
        &:focus {
          box-shadow: unset;
        }

        .fc-icon {
          height: 23px;
        }
      }
    }

    .fc-h-event {
      padding: 2px;
      background-color: $primary-color;
      border: 1px solid $primary-color;
    }

    .fc-toolbar-chunk {
      .fc-button-group {
        ~.fc-today-button {
          margin: 0 8px;
        }
      }
    }
  }
}

.fc-event-dragging {
  padding: 10px;
  background-color: var(--theme-default);
}

.fc {
  .fc-toolbar {
    flex-wrap: wrap;
    gap: 10px;
  }

  .fc-button-primary {
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.5);
    }
  }
}

// flatpickr
.main-inline-calender {
  &.input-group {
    flex-direction: column;

    .form-control {
      width: 100%;
      border-radius: 6px !important;

      &:focus {
        box-shadow: unset;
        border-color: var(--recent-border);
      }
    }

    .flatpickr-calendar {
      &.inline {
        width: 100%;
        border-radius: 6px !important;
      }
    }

    .flatpickr-innerContainer {
      display: block;
    }

    .flatpickr-rContainer {
      display: block;

      .flatpickr-weekdays {
        width: 100%;
      }

      .flatpickr-days {
        width: 100%;

        .dayContainer {
          width: 100%;
          max-width: none;
          min-width: unset;

          .flatpickr-day {
            border-radius: 5px;
            width: 100%;
            max-width: unset;

            &.prevMonthDay,
            &.nextMonthDay {
              color: rgba($badge-light-color, 0.6);
            }

            &:hover {
              background: var(--theme-default) !important;
              color: $white !important;
            }
          }
        }
      }
    }
  }
}

.flatpickr-day {

  &.selected,
  .endRange {
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default) !important;

    &:hover {
      background-color: var(--theme-default) !important;
      border-color: var(--theme-default) !important;

    }
  }
}

.main-flatpickr {
  .form-control {
    &:read-only {
      background-color: unset;
    }
  }
}

.flatpickr-months {
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.flatpickr-current-month {
  font-size: calc(14px + (19 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.flatpickr-calendar {
  &.animate {

    @media (max-width:400px) {
      width: 77%;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }

  .flatpickr-days {
    width: unset;

    .dayContainer {
      width: unset;
      min-width: unset;
      max-width: unset;
    }
  }

  &.rangeMode {
    .flatpickr-days {
      .dayContainer {
        .flatpickr-day {
          &.endRange {
            border-color: var(--theme-default);
            background-color: var(--theme-default);
          }
        }
      }
    }
  }
}

.flatpicker-calender {
  .form-control {
    @media (max-width:768px) {
      font-size: 14px;
    }
  }
}

.timepicker-wrapper {
  .row {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .input-group {
      .form-control {
        font-size: 14px;
        color: $dark-gray;

        &.input {
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
      }
    }
  }
}

/**=====================
   2.13 Data picker CSS End
==========================**/