/**=====================
    3.33 Invoices CSS start
==========================**/
// Invoice-1
.main-invoice-1 {
    body {
        font-family: Rubik, sans-serif;
        display: block;
        color: #000248;
    }

    .table-wrapper {
        width: 100%;
    }

    h2 {
        margin: 0;
        font-weight: 500;
        font-size: 34px;
    }

    h6 {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5;
        margin: 0;
    }

    span {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
    }

    .banner-image {
        margin: 13px 0 10px;
        width: 100%;
    }

    .order-details td span {
        margin-bottom: -4px;
        display: block;
    }

    .order-details th:first-child,
    .order-details td:first-child {
        min-width: 490px;
    }

    .order-details th:nth-child(2),
    .order-details td:nth-child(2) {
        width: 20%;
    }

    @media (max-width: 1199px) {
        .table-wrapper {
            width: 930px;
        }

        .address {
            width: 21% !important;
        }
    }
}

/**=====================
    3.33 Invoices CSS end
==========================**/