/**=====================
    3.57 Task CSS Start
==========================**/
.modal-open {
  .datepickers-container {
    z-index: 1075;
  }
}

.date-details {
  align-items: center;

  >div {
    +div {
      margin-left: 10px;
    }
  }
}

.taskadd {
  table {
    tr {
      td {
        border-top: none;

        .task_title_0 {
          color: $theme-body-font-color;
        }

        .task_desc_0 {
          @include multi-line-ellipsis(3, auto, vertical, hidden);
        }

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;

          a {
            @include square-box(25px, 25px, 3px, $light-danger);
            display: grid;
            place-items: center;

            svg {
              color: $danger-color;
            }
          }
        }

        svg {
          @include pixelstrap-size(18px, 18px);
          color: $semi-dark;
        }
      }

      &:first-child {
        td {
          padding-top: 20px;
        }
      }

      &:last-child {
        td {
          padding-bottom: 20px;
          border-bottom: unset;
        }
      }
    }
  }
}

// responsive
@media only screen and (max-width: 1660px) {
  .taskadd {
    table {
      tr {
        td {
          &:first-child {
            min-width: 160px;
          }

          &:nth-child(3) {
            min-width: 70px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .date-details {
    display: block ! important;
    margin-bottom: -10px;

    .d-inline-block {
      margin-bottom: 10px;
    }
  }

  .taskadd {
    table {
      tr {
        td {
          &:first-child {
            padding-left: 20px;
          }
        }

        &:first-child {
          td {
            padding-top: 20px;
          }
        }

        &:last-child {
          td {
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .taskadd {
    table {
      tr {
        td {
          &:nth-child(2) {
            min-width: 745px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .taskadd {
    table {
      tr {
        td {
          &:first-child {
            padding-left: 15px;
          }
        }

        &:first-child {
          td {
            padding-top: 15px;
          }
        }

        &:last-child {
          td {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

.main-tasks {
  .bootstrap-select {
    .dropdown-toggle {
      border-color: rgba($badge-light-color, 0.1) !important;
    }

    .dropdown-menu {
      .dropdown-item {
        opacity: 1;
        color: rgba($badge-light-color, 0.6) !important;
      }
    }

    .no-results {
      font-size: 14px;
      margin: 0;
      padding: 0 12px;
    }
  }
}

/**=====================
  3.57 Task CSS Ends
==========================**/